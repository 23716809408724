import React, { useCallback, useEffect, useRef, useState } from "react";
import firebase from "../../../firebase";
import ReactPlaceholder from "react-placeholder";
import { RectShape } from "react-placeholder/lib/placeholders";
import { Link } from "react-router-dom";
import MyLayout from "../../Components/MyLayout/index";
import MyDesktop from "../../Components/MyComponents/MyDesktop";
import MyMobile from "../../Components/MyComponents/MyMobile";
import VillageCard from "./VillageCard";
import MenuSelect from "./MenuSelect";
import "react-placeholder/lib/reactPlaceholder.css";
import "./index.css";
import axios from "axios";
import SearchBarWithDropdown from "../../Components/AdvanceSearch";
import Typography from "@mui/material/Typography";
import ExploreWebBanner from "../../Assets/img/background/explore_web_banner.png";
import ExploreMobBanner from "../../Assets/img/background/explore_mob_banner.png";
import { Box, CircularProgress } from "@mui/material";
import { Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import PageContainer from "../../Components/Container";
import { useInView } from "react-intersection-observer";
import Ticker from "../../Components/Ticker/ticker";
import AruP1 from "../../Assets/img/explore/AruP_1.png";
import AruP2 from "../../Assets/img/explore/AruP_2.jpg";
import AruP3 from "../../Assets/img/explore/AruP_3.jpg";
import AruP4 from "../../Assets/img/explore/AruP_4.jpg";

const ImageSlider = ({ images, interval = 3000 }) => {
  const [index, setIndex] = useState(0);
  const containerRef = useRef(null);

  useEffect(() => {
    const timer = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, interval);

    return () => clearInterval(timer);
  }, [images, interval]);

  useEffect(() => {
    const updateDimensions = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth;
        const containerHeight = containerRef.current.offsetHeight;
        const aspectRatio = 16 / 9;

        if (containerWidth / containerHeight > aspectRatio) {
          containerRef.current.style.width = `${containerHeight * aspectRatio}px`;
          containerRef.current.style.height = '100%';
        } else {
          containerRef.current.style.width = '100%';
          containerRef.current.style.height = `${containerWidth / aspectRatio}px`;
        }
      }
    };

    updateDimensions();
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  return (
    <div
      ref={containerRef}
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
        overflow: "hidden",
      }}
    >
      {images.map((image, i) => (
        <div
          key={i}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            opacity: i === index ? 1 : 0,
            transition: "opacity 1s ease-in-out",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={image}
            alt={`Slide ${i}`}
            style={{
              maxWidth: "100%",
              maxHeight: "100%",
              objectFit: "contain",
            }}
          />
        </div>
      ))}
    </div>
  );
};

const Village = (callback, deps) => {
  const [searchWord, setSearchWord] = useState("");
  const [villageList, setVillageList] = useState([]);
  const [searchClicked, setSearchClicked] = useState(false);
  const [allDistricts, setAllDistricts] = useState([]);
  const [allStates, setAllStates] = useState([]);
  const [allBlock, setAllBlock] = useState([]);
  const [allVillages, setVillages] = useState([]);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [selectedValueForVillageFilter, setSelectedValueForVillageFilter] =
    useState("");
  const [selectedValueForDistrictFilter, setSelectedValueForDistrictFilter] =
    useState("");
  const [selectedValueForStateFilter, setSelectedValueForStateFilter] =
    useState("");
  const [selectedValueForBlockFilter, setSelectedValueForBlockFilter] =
    useState("");
  const [isEndOfList, setIsEndOfList] = useState(false);
  const [selectedToggle, setSelectedToggle] = useState("live");
  const [isLoading, setIsLoading] = useState(false);
  const [lastDocumentFetched, setLastDocumentFetched] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [villageName, setVillageName] = useState(false);
  const [food, setFood] = useState(false);
  const [attire, setAttire] = useState(false);
  const [ornaments, setOrnaments] = useState(false);
  const [festivals, setFestivals] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  const db = firebase.firestore().collection("state_district_detail");
  const blockDb = firebase.firestore().collection("district_block_detail");

  const lastVillageElementRef = useRef();

  // Normal function instead of useCallback
  async function fetchVillage() {
    if (isLoading) return; // Avoid multiple simultaneous requests
    setIsLoading(true);

    let db = firebase.firestore().collection("large_village_list");

    if (selectedToggle) {
      db = db.where("live", "==", true).where("video", "!=", "");
    } else {
      db = db.where("video", "!=", "");
    }

    // Start query with the 'video' field if it's used in an inequality filter
    let query = db.orderBy("video").orderBy("village_name").limit(48);

    if (lastDocumentFetched) {
      query = query.startAfter(lastDocumentFetched);
    }

    try {
      const snapshot = await query.get();
      if (snapshot.empty) {
        setIsEndOfList(true);
        setIsLoading(false);
        return;
      }

      const documents = snapshot.docs;
      const list = documents.map((doc) => ({
        key: doc.id,
        village_key: doc.data()["village_document_id"],
        state_name: doc.data()["state_name"],
        village_name: doc.data()["village_name"],
        thumbnail: doc.data()["video_thumbnail"],
        thumbnaiId: doc.data()["thumbnail_name"],
        live: doc.data()["live"] || false,
        isGoodStories: doc.data()["isGoodStories"] || false,
      }));

      setVillageList((prev) => [...prev, ...list]);
      setLastDocumentFetched(documents[documents.length - 1]); // Update last document to paginate
      setIsEndOfList(list.length < 48); // If fewer than 48 results, set end of list flag
    } catch (error) {
      console.error("Error fetching villages: ", error);
    } finally {
      setIsLoading(false);
    }
  }

  const handleSearch = async (page = 0) => {
    setLastDocumentFetched(null);
    if (page <= totalPages) {
      setCurrentPage(page);
      setIsLoading(true); // Show loading spinner

      // Base URL
      let query = `https://asia-south1-mgmd-356213.cloudfunctions.net/exploreElasticSearch?page=${page}&restrict=${
        selectedToggle === "live" ? "custom" : "true"
      }`;

      // Add search query if present
      if (searchValue) {
        query += `&query=${encodeURIComponent(searchValue)}`;
      }

      // Start customFilter string
      let customFilter = "";

      if (selectedToggle === "live") {
        customFilter += "live:true";
      }

      if (selectedValueForStateFilter) {
        customFilter +=
          (customFilter ? " AND " : "") +
          `state_name:${encodeURIComponent(selectedValueForStateFilter)}`;
      }

      if (selectedValueForDistrictFilter) {
        customFilter +=
          (customFilter ? " AND " : "") +
          `district_name:${encodeURIComponent(selectedValueForDistrictFilter)}`;
      }

      if (selectedValueForBlockFilter) {
        customFilter +=
          (customFilter ? " AND " : "") +
          `block_name:${encodeURIComponent(selectedValueForBlockFilter)}`;
      }

      if (selectedValueForVillageFilter) {
        customFilter +=
          (customFilter ? " AND " : "") +
          `village_name:${encodeURIComponent(selectedValueForVillageFilter)}`;
      }

      // Append customFilter if it has any content
      if (customFilter) {
        query += `&customFilter=${customFilter}`;
      }

      // Restrict searchable attributes based on villageName and food
      const restrictSearchableAttributes = [];

      if (villageName) {
        restrictSearchableAttributes.push("village_name");
      }

      // Check if food should be included
      if (food) {
        restrictSearchableAttributes.push("data.traditional_food.description");
        restrictSearchableAttributes.push("data.traditional_food.title");
      }

      if (attire) {
        restrictSearchableAttributes.push("data.traditional_dress.description");
        restrictSearchableAttributes.push("data.traditional_dress.title");
      }

      if (ornaments) {
        restrictSearchableAttributes.push("data.ornaments.description");
        restrictSearchableAttributes.push("data.ornaments.title");
      }

      if (festivals) {
        restrictSearchableAttributes.push("data.festivals.description");
        restrictSearchableAttributes.push("data.festivals.title");
      }

      if (restrictSearchableAttributes.length > 0) {
        query += `&restrictSearchableAttributes=${restrictSearchableAttributes.join(
          ","
        )}`;
      }

      try {
        // Fetch search results with dynamic query
        const response = await axios.get(query);
        const villageData = response.data.hits;
        const newTotalPages = response.data.nbPages;

        // Update state with new data
        setTotalPages(newTotalPages);
        setIsEndOfList(page >= newTotalPages);
        // console.log(villageData, "village DXata");
        // Map fetched data to the desired format
        const list = villageData.map((obj) => ({
          key: obj?.objectID,
          // village_key: obj?.objectID,
          village_key: obj?.village_document_id,
          state_name: obj?.state_name,
          village_name: obj?.village_name,
          thumbnaiId: obj?.thumbnail_name,
          live: obj?.live || false,
          isGoodStories: obj?.isGoodStories || false,
        }));
        // console.log(list, "searchlist");
        setVillageList((prevList) => [...prevList, ...list]);
      } catch (e) {
        setVillageList([]);
        console.error("Error fetching search results:", e);
      } finally {
        setIsLoading(false); // Hide loading spinner
      }
    }
  };

  useEffect(() => {
    const fetchStatesAndDistricts = async () => {
      try {
        // Reset state values
        setSelectedValueForDistrictFilter("");
        setSelectedValueForStateFilter("");
        setSelectedValueForBlockFilter("");
        setSelectedValueForVillageFilter("");

        // Get states
        const statesSnapshot = await firebase
          .firestore()
          .collection("state_detail")
          .limit(100)
          .get();
        const tempStates = statesSnapshot.docs.map((state) => ({
          value: state.data().state_name,
        }));
        setAllStates(tempStates);
      } catch (err) {
        console.error("Error fetching states, districts, and blocks:", err);
      }
    };

    fetchStatesAndDistricts().then();
  }, []);

  useEffect(() => {
    let filters = JSON.parse(localStorage.getItem("filters"));
    if (filters?.stateName) {
      handleStateChange(filters.stateName);
    }
    if (filters?.districtName) {
      handleDistrictChange(filters.districtName);
    }
    if (filters?.blockName) {
      handleBlockChange(filters.blockName);
    }
    if (filters?.villageName) {
      setSelectedValueForVillageFilter(filters.villageName);
    }
    if (filters?.searchValue) {
      setSearchValue(filters?.searchValue);
    }
  }, []);

  useEffect(() => {
    const filters = JSON.parse(localStorage.getItem("filters") || "{}");
    if (
      !filters?.stateName &&
      !filters?.districtName &&
      !filters?.blockName &&
      !filters?.villageName &&
      !filters?.searchValue &&
      !searchWord
    ) {
      if (selectedToggle) {
        fetchVillage().then();
      }
    }
  }, [selectedToggle]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !isLoading && !isEndOfList) {
          if (
            !searchValue &&
            !selectedValueForStateFilter &&
            !selectedValueForDistrictFilter &&
            !selectedValueForBlockFilter &&
            !villageName &&
            !food &&
            !attire &&
            !ornaments &&
            !festivals
          ) {
            fetchVillage().then();
          } else {
            handleSearch(currentPage + 1).then();
          }
        }
      },
      { threshold: 0.4 }
    );

    if (lastVillageElementRef.current) {
      observer.observe(lastVillageElementRef.current);
    }

    return () => {
      if (lastVillageElementRef.current) {
        observer.unobserve(lastVillageElementRef.current);
      }
    };
  }, [isLoading, isEndOfList, fetchVillage]);

  useEffect(() => {
    if (
      searchValue.length > 2 ||
      selectedValueForStateFilter ||
      selectedValueForDistrictFilter ||
      selectedValueForBlockFilter ||
      selectedValueForVillageFilter
    ) {
      setVillageList([]);
      handleSearch().then();
    }
  }, [
    searchValue,
    selectedValueForStateFilter,
    selectedValueForDistrictFilter,
    selectedValueForBlockFilter,
    selectedValueForVillageFilter,
  ]);

  useEffect(() => {
    if (searchValue.length > 2) {
      setVillageList([]);
      handleSearch().then();
    }
  }, [villageName, food, ornaments, attire, festivals]);

  const clearState = () => {
    localStorage.removeItem("filters");
    setLastDocumentFetched(null);
    setSearchValue("");
    setVillageName(false);
    setFood(false);
    setAttire(false);
    setOrnaments(false);
    setFestivals(false);
    setTotalPages(0);
    setCurrentPage(0);
    setSelectedValueForStateFilter("");
    setSelectedValueForDistrictFilter("");
    setSelectedValueForBlockFilter("");
    setSelectedValueForVillageFilter("");
    setVillageList([]);
  };

  const handleToggleChange = (newToggle) => {
    if (newToggle !== null) {
      clearState();
      setVillageList([]); // Clear the village list
      setSelectedToggle(newToggle); // Update the selected toggle
    }
  };

  const onReset = () => {
    fetchVillage().then();
    setSearchWord("");
    setSearchClicked(false);
  };

  const handleStateChange = async (value) => {
    setSelectedValueForStateFilter(value);
    setSelectedValueForDistrictFilter("");
    setSelectedValueForBlockFilter("");
    setSelectedValueForVillageFilter("");
    setAllDistricts([]);
    setAllBlock([]);

    let getFilters = JSON.parse(localStorage.getItem("filters"));
    getFilters = {
      ...getFilters,
      stateName: value,
    };
    localStorage.setItem("filters", JSON.stringify(getFilters));
    try {
      if (value) {
        const stateQuerySnapshot = await db
          .where("state_name", "==", value)
          .get();
        const newDistricts = Array.from(
          new Set(
            stateQuerySnapshot.docs.map((doc) => doc.data().district_name)
          )
        ).map((district) => ({
          value: district,
        }));

        setAllDistricts(newDistricts);

        // Fetch blocks for all districts of the selected state
        const districtNames = newDistricts.map((d) => d.value);
        // const blocks = await fetchBlocksByDistricts(districtNames);
        // setAllBlock(blocks);
      }
    } catch (error) {
      console.error("Error fetching districts and blocks:", error);
    } finally {
    }
  };

  const handleDistrictChange = async (value) => {
    setSelectedValueForDistrictFilter(value);
    setSelectedValueForBlockFilter("");
    setSelectedValueForVillageFilter("");
    setAllBlock([]);

    let getFilters = JSON.parse(localStorage.getItem("filters"));
    getFilters = {
      ...getFilters,
      districtName: value,
    };
    localStorage.setItem("filters", JSON.stringify(getFilters));

    // Update blocks based on district
    try {
      if (value) {
        const blockQuerySnapshot = await blockDb
          .where("district_name", "==", value)
          .get();
        const newBlocks = blockQuerySnapshot.docs.map((doc) => ({
          value: doc.data().block_name,
        }));
        setAllBlock(newBlocks);
      }
    } catch (error) {
      console.error("Error fetching blocks:", error);
    } finally {
    }
  };

  const handleBlockChange = async (e) => {
    let arr = [];
    setSelectedValueForVillageFilter("");
    setSelectedValueForBlockFilter(e);
    let getFilters = JSON.parse(localStorage.getItem("filters"));
    getFilters = {
      ...getFilters,
      blockName: e,
    };
    localStorage.setItem("filters", JSON.stringify(getFilters));
    const db = firebase.firestore();
    const collectionName = db.collection("large_village_list");
    const querySnapshot = await collectionName
      .where("block_name", "==", e)
      .get();
    if (!querySnapshot.empty) {
      querySnapshot.forEach((doc) =>
        arr.push({ value: doc.data().village_name })
      );
    }
    setVillages(arr);
  };

  const getImages = () => {
    switch (selectedValueForStateFilter) {
      case "Arunachal Pradesh":
        return [
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FArunachal%20Pradesh%2FAruP_1.png?alt=media&token=2afb9ae3-4f54-4c24-b75c-3bb164447917",
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FArunachal%20Pradesh%2FAruP_2.jpg?alt=media&token=b41b2a57-af97-481f-b74c-17faefed883e",
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FArunachal%20Pradesh%2FAruP_3.jpg?alt=media&token=997ce50d-be51-43ba-a519-414b30c9c92e",
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FArunachal%20Pradesh%2FAruP_4.jpg?alt=media&token=8adf7de5-5db2-4fe3-9ee8-dd2129d4997f",
        ];
      case "Andhra Pradesh":
        return [
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FAndhra%20Pradesh%2F2.png?alt=media&token=85ad8841-7eee-4ae4-a814-48a9d4cd6e60",
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FAndhra%20Pradesh%2F5.png?alt=media&token=0b50ee27-a27f-4534-b832-65a2c8c6d98c",
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FAndhra%20Pradesh%2FScreenshot%202025-03-03%20105520.png?alt=media&token=7481aad8-c244-464a-9ad6-a861add9da27",
        ];
      case "Andaman and Nicobar Islands":
        return [
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FAndaman%20%26%20Nicobar%20(UT)%2Fimage_0.jpg?alt=media&token=4f9e8bc3-691f-4c23-bd60-f84a9cf1fe5c",
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FAndaman%20%26%20Nicobar%20(UT)%2Fimage_1.jpg?alt=media&token=e9cde431-daa7-4e8d-bef4-2727ef77822b",
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FAndaman%20%26%20Nicobar%20(UT)%2Fimage_2.jpg?alt=media&token=90a7caef-7cab-41aa-ac97-38a3babe05ee",
        ];
      case "Assam":
        return [
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FAssam%2Fimage_0.jpg?alt=media&token=e62d241e-1b18-4a23-994f-98656904b479",
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FAssam%2Fimage_1.jpg?alt=media&token=6a7a4ce8-bdb4-44c2-9993-da4b857a45a2",
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FAssam%2Fimage_2.jpg?alt=media&token=4c41e256-37af-495b-a7cf-579ab6a535cf",
        ];
      case "Bihar":
        return [
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FBihar%2Fimage_0.jpg?alt=media&token=141f8c63-f6be-4451-8c78-86fbe43ea424",
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FBihar%2Fimage_1.jpg?alt=media&token=a5120305-0759-4b7b-b9b1-93f196706377",
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FBihar%2Fimage_2.jpg?alt=media&token=11373fec-9c94-4f78-902e-c3f7a4cced58",
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FBihar%2Fimage_3.jpg?alt=media&token=ee74664a-dad2-46d1-beda-e13d4a4c42aa",
        ];
      case "Chandigarh":
        return [
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FChandigarh%20(UT)%2Fimage_0.jpg?alt=media&token=c881d9c2-55a2-49d3-a26b-39628e093948",
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FChandigarh%20(UT)%2Fimage_1.jpg?alt=media&token=965bc409-76aa-4c4e-a1d6-4dc60dc5e0ef",
        ];
      case "Chhattisgarh":
        return [
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FChhattisgarh%2Fimage_0.jpg?alt=media&token=2e6c7f57-9103-4219-988f-df62b1cd15c1",
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FChhattisgarh%2Fimage_1.jpg?alt=media&token=8d83d353-bb7c-4f8b-b8ba-cf5a7b8c0489",
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FChhattisgarh%2Fimage_2.jpg?alt=media&token=5b517f49-5eb4-4b52-8969-c54cacdeedfa",
        ];
      case "Delhi":
        return [
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FDelhi%20%5BNational%20Capital%20Territory%20(NCT)%5D%2FScreenshot%202025-03-05%20092141.png?alt=media&token=066a7bcc-1ba2-4d7b-9963-e2b8fcce6151",
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FDelhi%20%5BNational%20Capital%20Territory%20(NCT)%5D%2FScreenshot%202025-03-05%20214918.png?alt=media&token=fe88ab28-e5f8-41d5-93c0-73ce1d1b8fe3",
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FDelhi%20%5BNational%20Capital%20Territory%20(NCT)%5D%2FScreenshot%202025-03-05%20215137.png?alt=media&token=0c276a59-b934-4973-a241-4695fb3445ac",
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FDelhi%20%5BNational%20Capital%20Territory%20(NCT)%5D%2FScreenshot%202025-03-05%20215255.png?alt=media&token=e263aff1-d67a-4b3e-9025-5bc1473af02d",
        ];
      case "Goa":
        return [
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FGoa%2Fimage_0.jpg?alt=media&token=9018b6e5-68f1-495d-8950-d7e5b3ae0b3f",
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FGoa%2Fimage_1.jpg?alt=media&token=20c86e53-605c-4e50-beb6-b00a7dfcdad2",
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FGoa%2Fimage_2.jpg?alt=media&token=d1f876b7-43b8-4980-abf9-51209d1a04b4",
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FGoa%2Fimage_3.jpg?alt=media&token=f78edd22-13a4-45b0-a608-56fe9143e160",
        ];
      case "Gujarat":
        return [
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FGujarat%2Fimage_0.jpg?alt=media&token=dfc56a63-9f09-4703-b8b7-bee67e20869d",
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FGujarat%2Fimage_1.jpg?alt=media&token=c8ca5e6b-64be-459d-8cd1-3f8514cfd6ed",
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FGujarat%2Fimage_2.jpg?alt=media&token=edb6a41d-ceb9-4658-b338-f3c357306702",
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FGujarat%2Fimage_3.jpg?alt=media&token=118f2db1-bd0c-4a8a-8d39-0a03558e880c",
        ];
      case "Haryana":
        return [
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FHaryana%2Fimage_0.jpg?alt=media&token=58b82056-fbb7-4dc9-8d41-29d7187ac94d",
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FHaryana%2Fimage_1.jpg?alt=media&token=213911a9-e160-41ff-9fe1-9c908b129d2d",
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FHaryana%2Fimage_2.jpg?alt=media&token=90a6e5ca-1926-4165-8615-28c0f80b9efb",
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FHaryana%2Fimage_3.jpg?alt=media&token=56de7cfe-b61a-4e0a-aae1-86ef6d6ae56a",
        ];
      case "Himachal Pradesh":
        return [
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FHimachal%20Pradesh%2Fimage_1.jpg?alt=media&token=1da053eb-aee8-4637-be21-63f8cf3ddf3b",
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FHimachal%20Pradesh%2Fimage_3.jpg?alt=media&token=1914668a-238f-4f5c-97ef-ec2f1689458a",
        ];
      case "Jammu and Kashmir":
        return [
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FJammu%20%26%20Kashmir%20(UT)%2FScreenshot%202025-03-04%20152346.png?alt=media&token=472d2310-c26f-410a-ba1a-8fd8209f2a8e",
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FJammu%20%26%20Kashmir%20(UT)%2FScreenshot%202025-03-04%20152604.png?alt=media&token=e4260e08-130e-4bda-a71e-99f4d5082c58",
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FJammu%20%26%20Kashmir%20(UT)%2FScreenshot%202025-03-04%20152633.png?alt=media&token=c2ce45ec-9ece-4b00-b599-69ea4a096510",
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FJammu%20%26%20Kashmir%20(UT)%2FScreenshot%202025-03-04%20152737.png?alt=media&token=09c86d98-5929-442b-bb34-3d91de2ceeae",
        ];
      case "Jharkhand":
        return [
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FJharkhand%2F5%20(1).png?alt=media&token=b705ef7f-d7c1-4e01-a50d-c9ef53b242d3",
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FJharkhand%2F6.png?alt=media&token=2c9887db-a778-495b-8695-27bdbce2176a",
        ];
      case "Karnataka":
        return [
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FKarnataka%2Fimage_0.jpg?alt=media&token=98f522f1-13a2-4c6b-8265-90bf35112eda",
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FKarnataka%2Fimage_1.jpg?alt=media&token=e76acd69-7eb1-47f3-b1ea-aaf03f0157d6",
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FKarnataka%2Fimage_2.jpg?alt=media&token=d6dc2fa9-a609-4e3b-9ba9-954e0a6ecfa8",
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FKarnataka%2Fimage_3.jpg?alt=media&token=25d80f93-6e12-4535-a958-e7184fc4abfa",
        ];
      case "Kerala":
        return [
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FKerala%2Fimage_0.jpg?alt=media&token=652205e2-c2d4-4464-a6db-47daab9cbfa7",
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FKerala%2Fimage_1.jpg?alt=media&token=5896451c-7f3f-442e-9ff5-06fd8d0ef841",
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FKerala%2Fimage_2.jpg?alt=media&token=a17a9232-69c7-4006-82a4-49fa9dae8dd9",
        ];
      case "LADAKH":
        return [
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FLadakh%20(UT)%2Fimage_0.jpg?alt=media&token=0300b7fc-d60b-4970-b01e-2ee82e437577",
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FLadakh%20(UT)%2Fimage_1.jpg?alt=media&token=0f235361-b480-40d6-92b7-4f87d51cf7c8",
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FLadakh%20(UT)%2Fimage_2.jpg?alt=media&token=8c03850a-747b-4186-af67-f90de7f49868",
        ];
      case "Lakshadweep":
        return [
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FLakshadweep%20(UT)%2Fimage_0.jpg?alt=media&token=a6c0a6ee-a74d-4bcc-834d-3da00db5f513",
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FLakshadweep%20(UT)%2Fimage_1.jpg?alt=media&token=80661b25-5b80-48f4-8e95-0eddd3e4b2a5",
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FLakshadweep%20(UT)%2Fimage_2.jpg?alt=media&token=b3b663ba-2e08-45e6-8f82-962b3d4c1ff8",
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FLakshadweep%20(UT)%2Fimage_3.jpg?alt=media&token=c9793643-f787-4ea7-8b57-d6fc0bea2976",
        ];
      case "Madhya Pradesh":
        return [
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FMadhya%20Pradesh%2Fimage_0.jpg?alt=media&token=6fc4b5ef-2dc9-4640-aaa8-268c9fb1ba8d",
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FMadhya%20Pradesh%2Fimage_1.jpg?alt=media&token=402ffb4f-36b2-4217-8bf7-9e740b613ad0",
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FMadhya%20Pradesh%2Fimage_2.jpg?alt=media&token=1526720b-b254-49e6-9b3a-2bfd9a058c65",
        ];
      case "Maharashtra":
        return [
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FMaharashtra%2Fimage_0.jpg?alt=media&token=3a8c7385-acc1-4508-9c22-a79951f0e9d8",
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FMaharashtra%2Fimage_1.jpg?alt=media&token=7bd17fcc-19fe-4949-9676-2cbc5193e754",
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FMaharashtra%2Fimage_2.jpg?alt=media&token=cd85016a-bfd9-4702-9a99-2923c8b19291",
        ];
      case "Manipur":
        return [
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FManipur%2Fimage_0.jpg?alt=media&token=84205c5e-4f7d-41ab-821d-fbcdcfa67f30",
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FManipur%2Fimage_1.jpg?alt=media&token=183c6c11-9ded-4c0e-b03b-a4e3ab818053",
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FManipur%2Fimage_2.jpg?alt=media&token=1d464f17-bea7-43bc-aa58-1593e646eb86",
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FManipur%2Fimage_3.jpg?alt=media&token=859b682d-671f-4e42-937e-af9a12e3e9d8",
        ];
      case "Meghalaya":
        return [
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FMeghalaya%2Fimage_0.jpg?alt=media&token=1887c5b4-ff9a-477f-82af-8684b581cd31",
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FMeghalaya%2Fimage_1.jpg?alt=media&token=c23ae353-6216-4b74-9dcf-ac078c2b9b29",
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FMeghalaya%2Fimage_2.jpg?alt=media&token=9d937df0-7056-411d-b638-94699e24c031",
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FMeghalaya%2Fimage_3.jpg?alt=media&token=13f5e29a-403a-419f-a9ce-9c7d82182d8b",
        ];
      case "Mizoram":
        return [
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FMizoram%2Fimage_0.jpg?alt=media&token=7d7b07d4-ce14-431d-8b40-f39fcf7acbdd",
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FMizoram%2Fimage_1.jpg?alt=media&token=5978fa06-334f-4316-9b22-5ed92ad28629",
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FMizoram%2Fimage_2.jpg?alt=media&token=7aab680f-dd65-43c7-b58a-7368002f7e58",
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FMizoram%2Fimage_3.jpg?alt=media&token=22a1b240-2fb9-47a0-b8b1-9ce764d7ff43",
        ];
      case "Nagaland":
        return [
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FNagaland%2Fimage_0.jpg?alt=media&token=54a52818-752d-493d-8300-f32da21dd01b",
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FNagaland%2Fimage_1.jpg?alt=media&token=b14da561-13f9-4a4f-8766-05b4b9db7261",
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FNagaland%2Fimage_2.jpg?alt=media&token=23ccb7dd-e92b-4b82-8cc7-95033700dcec",
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FNagaland%2Fimage_3.jpg?alt=media&token=a7f4ca3a-f6ff-445a-9c9d-81ebb0421d52",
        ];
      case "Odisha":
        return [
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FOdisha%2Fimage_0.jpg?alt=media&token=71b30aab-86e9-4dae-a478-cacebeb52488",
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FOdisha%2Fimage_1.jpg?alt=media&token=39e3df8e-ac4e-4985-b211-c712903ca93c",
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FOdisha%2Fimage_2.jpg?alt=media&token=72c8ea1f-6005-4913-a59c-347f6b300fe3",
        ];
      case "Puducherry":
        return [
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FPuducherry%20(UT)%2Fimage_0.jpg?alt=media&token=46922485-c12b-4882-bf03-97b98fef3e7c",
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FPuducherry%20(UT)%2Fimage_1.jpg?alt=media&token=92283311-9f9c-4304-a422-265890d47173",
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FPuducherry%20(UT)%2Fimage_2.jpg?alt=media&token=519685eb-2c26-4fb1-ad19-ae21a6d86885",
        ];
      case "Punjab":
        return [
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FPunjab%2Fimage_0.jpg?alt=media&token=0778adb1-edf6-45a7-b0ba-4a53fe62e189",
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FPunjab%2Fimage_1.jpg?alt=media&token=bc64b4e4-f720-468d-b918-d1ec504b59b2",
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FPunjab%2Fimage_2.jpg?alt=media&token=d6cfdeef-5d7a-4dd3-ba3e-90bf57ecb599",
        ];
      case "Rajasthan":
        return [
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FRajasthan%2Fimage_0.jpg?alt=media&token=a7b2e4b1-7e7e-45c7-af58-7550513241aa",
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FRajasthan%2Fimage_1.jpg?alt=media&token=e111ff37-d21f-43e7-bf05-cfebd0962109",
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FRajasthan%2Fimage_2.jpg?alt=media&token=749f6cdd-1a32-4909-b9d9-6d88211689a6",
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FRajasthan%2Fimage_3.jpg?alt=media&token=e563b1bb-5980-42da-9ebb-c6ec1a56eb27",
        ];
      case "Sikkim":
        return [
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FSikkim%2Fimage_0.jpg?alt=media&token=9dac487c-4be4-4f13-a742-73b62cd9b7ab",
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FSikkim%2Fimage_1.jpg?alt=media&token=f191bfcc-2358-4026-8bd1-0fead1c5f9d0",
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FSikkim%2Fimage_2.jpg?alt=media&token=bacf42be-5a9c-41d7-ba77-f280446b2fdf",
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FSikkim%2Fimage_3.jpg?alt=media&token=bd2c82b5-7065-4e8d-a05d-62941b955cd6",
        ];
      case "Tamil Nadu":
        return [
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FTamil%20Nadu%2Fimage_0.jpg?alt=media&token=46b4159c-5ab1-4b5f-84b8-373399b0a154",
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FTamil%20Nadu%2Fimage_1.jpg?alt=media&token=bd63da8f-3750-4812-a934-f4cff57ce79d",
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FTamil%20Nadu%2Fimage_2.jpg?alt=media&token=7fd04aba-6def-402d-a8e8-5e8a53e0c439",
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FTamil%20Nadu%2Fimage_3.jpg?alt=media&token=1aeb4b7d-6c38-48f9-8518-886ee283c17c",
        ];
      case "Telangana":
        return [
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FTelangana%2Fimage_0.jpg?alt=media&token=26bd54e0-9a35-4cfa-bc0a-5734ebc8a6e3",
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FTelangana%2Fimage_1.jpg?alt=media&token=93bef96c-ed74-498b-ac02-190e2eafa935",
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FTelangana%2Fimage_2.jpg?alt=media&token=ca5b4194-6070-4573-b333-9f115a7dbb3d",
        ];
      case "The Dadra and Nagar Haveli and Daman and Diu":
        return [
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FDadra%20%26%20Nagar%20Haveli%20and%20Daman%20%26%20Diu%20(UT)%2Fimage_0.jpg?alt=media&token=a25c4d78-87a3-44f1-9708-44d4dfb4b41a",
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FDadra%20%26%20Nagar%20Haveli%20and%20Daman%20%26%20Diu%20(UT)%2Fimage_1.jpg?alt=media&token=872666d5-ef37-4729-9c17-03852679cbb2",
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FDadra%20%26%20Nagar%20Haveli%20and%20Daman%20%26%20Diu%20(UT)%2Fimage_2.jpg?alt=media&token=7f4bc26a-49e8-45d5-9cf0-1a4f56f50682",
        ];
      case "Tripura":
        return [
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FTripura%2Fimage_1.jpg?alt=media&token=2c1cae6e-47ab-44be-a02c-a9f72f9dac1c",
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FTripura%2Fimage_2.jpg?alt=media&token=fbeaddb1-6e75-4c44-9b15-a71fa49d3fa7",
        ];
      case "Uttar Pradesh":
        return [
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FUttar%20Pradesh%2Fimage_0.jpg?alt=media&token=f4d82956-9689-45da-ac36-8d54ce67c5d1",
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FUttar%20Pradesh%2Fimage_2.jpg?alt=media&token=7ffb32d9-df72-4f9f-be4c-e33157ba171e",
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FUttar%20Pradesh%2Fimage_3.jpg?alt=media&token=983642c3-1158-4b0c-8f4b-9b12d0a75645",
        ];
      case "Uttarakhand":
        return [
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FUttarakhand%2Fimage_1.jpg?alt=media&token=7aada3a3-870c-4317-8137-8dc6afd061b3",
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FUttarakhand%2Fimage_3.jpg?alt=media&token=c3032885-337e-483d-b2e1-7b57e8760694",
        ];
      case "West Bengal":
        return [
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FWest%20Bengal%2F1.jpg?alt=media&token=b4cf237a-1e83-4923-bcd7-2e85d838a6c9",
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FWest%20Bengal%2F2.jpg?alt=media&token=9b325394-19ff-4b04-8942-95036be5aa20",
          "https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/banner_image%2FWest%20Bengal%2F4.png?alt=media&token=bc61adb5-11bb-480a-9411-a2272cda9b96",
        ];
      default:
        return "";
    }
  };

  return (
    <PageContainer
      keywords="Explore Villages, Discover Cultural Heritage"
      title="Explore Villages with MGMD | Discover India's Rural Legacy"
      description="Dive deep into India’s rural heritage. Explore various villages, their traditions, history, and cultural significance with MGMD’s comprehensive database and mapping tools."
      canonical="https://mgmd.gov.in/explore"
    >
      <Ticker />

      <MyLayout>
        <div className="col-md-12 custom-list">
          <div className="pb-3 search-content justify-content-between">
            <MyDesktop>
              <div style={divStyle}>
                <img
                  src={ExploreWebBanner}
                  style={{ width: "62%", position: "absolute", zIndex: "2" }}
                />
                <Box
                  sx={{
                    position: "absolute",
                    zIndex: "2",
                    width: "35%",
                    top: "12%",
                    left: "10%",
                    marginBottom: "10%",
                  }}
                >
                  <Typography
                    variant="h4"
                    color="white"
                    sx={{
                      fontFamily: "Playfair Display, serif",
                      fontSize: "2rem",
                    }}
                  >
                    Welcome to Mera Gaon Meri Dharohar: The largest cultural
                    repository of the villages of India.
                  </Typography>

                  <Typography
                    variant="subtitle1"
                    mt={2}
                    color="white"
                    sx={{ fontFamily: "Inter, sans-serif", fontSize: "1rem" }}
                  >
                    Discover the nation's diversity through its vibrant
                    villages, each showcased with its unique cultural profile.
                    Explore local tales, ancient traditions, rich heritage and
                    more. <br />
                    <br />
                    Presenting 6.5 Lakh+ Villages | 4.4 Lakh Surveyed so far
                  </Typography>

                  <br />

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <button
                      className="btn"
                      style={{
                        borderColor: "white",
                        color: "white",
                        padding: "5px 30px 5px 30px",
                      }}
                      onClick={handleShow}
                    >
                      Know More
                    </button>

                    <Modal show={show} onHide={handleClose} size="lg" centered>
                      <Modal.Header closeButton></Modal.Header>
                      <Modal.Body>
                        <div className="embed-responsive embed-responsive-16by9">
                          <video
                            className="embed-responsive-item"
                            autoPlay
                            muted
                            controls
                            width="100%"
                            height="400px"
                          >
                            <source
                              src="https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/videos%2Fknowmore.mp4?alt=media&token=1cc2fc23-c23a-4c9d-af97-636d9428627b"
                              type="video/mp4"
                            />
                            Your browser does not support the video tag.
                          </video>
                        </div>
                      </Modal.Body>
                    </Modal>
                  </div>
                </Box>
                <Box
                  width="60%"
                  height="100%"
                  sx={{ position: "absolute", left: "47%", overflow: "hidden" }}
                >
                  <>
                    {selectedValueForStateFilter ? (
                      <ImageSlider images={getImages()} interval={3000} />
                    ) : (
                      <video width="100%" controls={false} loop autoPlay muted>
                        <source
                          src="https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/videos%2Fexplorebanner.mp4?alt=media&token=5fcdfa2d-1b21-46b0-a923-84c92e68c5a1"
                          type="video/mp4"
                        />
                        Your browser does not support the video tag.
                      </video>
                    )}
                  </>
                </Box>
              </div>
              <div className="row col-12 g-0 mt-3">
                <SearchBarWithDropdown
                  setSearchValue={setSearchValue}
                  searchValue={searchValue}
                  villageName={villageName}
                  food={food}
                  attire={attire}
                  ornaments={ornaments}
                  festivals={festivals}
                  setVillageName={setVillageName}
                  setFood={setFood}
                  setAttire={setAttire}
                  setOrnaments={setOrnaments}
                  setFestivals={setFestivals}
                />
              </div>
              <div className="text-disclaimer-with-edit py-2">
                Register New Village, click
                <Link
                  className="text-primary px-1"
                  to={localStorage.getItem("user") ? "/add-village" : "/login"}
                >
                  here.
                </Link>
              </div>
            </MyDesktop>
            <MyMobile>
              <div style={divMobStyle}>
                <Box
                  width="100%"
                  sx={{
                    position: "absolute",
                    overflow: "hidden",
                    height: "60vh",
                  }}
                >
                  <video
                    height="100%"
                    width="100%"
                    style={{
                      objectFit: "cover",
                      objectPosition: "center",
                    }}
                    controls={false}
                    autoPlay
                    muted
                    // Set the source of the video
                  >
                    <source
                      src="https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/videos%2Fexplorebanner.mp4?alt=media&token=5fcdfa2d-1b21-46b0-a923-84c92e68c5a1"
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                </Box>
                <img
                  src={ExploreMobBanner}
                  style={{
                    width: "100%",
                    position: "absolute",
                    zIndex: "1",
                    bottom: "35px",
                  }}
                />
                <Box
                  sx={{
                    position: "absolute",
                    zIndex: "2",
                    width: "90%",
                    top: "65%",
                    left: "5%",
                  }}
                >
                  <Typography variant="subtitle1" color="white">
                    Welcome to Mera Gaon Meri Dharohar: The largest cultural
                    repository of India's villages.
                  </Typography>
                  <Typography variant="subtitle2" mt={5} color="white">
                    Discover the nation's diversity through its vibrant
                    villages, each showcased with its unique cultural profile.
                    Explore local tales, ancient traditions, rich heritage and
                    more. <br />
                    <br />
                    Presenting 6.5 Lakh+ Villages | 4.4 Lakh Surveyed so far
                  </Typography>
                </Box>
              </div>
              <div className="row col-12 g-0 mt-3">
                <SearchBarWithDropdown
                  setSearchValue={setSearchValue}
                  searchValue={searchValue}
                  villageName={villageName}
                  food={food}
                  attire={attire}
                  ornaments={ornaments}
                  festivals={festivals}
                  setVillageName={setVillageName}
                  setFood={setFood}
                  setAttire={setAttire}
                  setOrnaments={setOrnaments}
                  setFestivals={setFestivals}
                />
              </div>
            </MyMobile>
          </div>
          <div className="drop-down-filter d-flex pb-3">
            <div className="filter-part">
              <div className="row">
                <div className="col">
                  <MenuSelect
                    attribute="state_name"
                    f_name="State"
                    limit={100}
                    className="state_filter"
                    passedDownItems={allStates}
                    onChangeFun_={handleStateChange}
                    selectedValue={selectedValueForStateFilter}
                    isDisable={allStates.length < 1}
                  />
                </div>
                <div className="col">
                  <MenuSelect
                    attribute="district_name"
                    f_name="District"
                    limit={1000}
                    className="distric_filter"
                    passedDownItems={allDistricts}
                    onChangeFun_={handleDistrictChange}
                    selectedValue={selectedValueForDistrictFilter}
                    isDisable={!selectedValueForStateFilter}
                  />
                </div>
                <div className="col">
                  <MenuSelect
                    attribute="tehsil"
                    f_name="Block"
                    limit={500}
                    className="block_filter"
                    passedDownItems={allBlock}
                    onChangeFun_={(e) => handleBlockChange(e)}
                    selectedValue={selectedValueForBlockFilter}
                    isDisable={!selectedValueForDistrictFilter}
                  />
                </div>
                {/* { allVillages.length > 0 && ( */}
                <div className="col">
                  <MenuSelect
                    attribute="tehsil"
                    f_name="Village"
                    limit={500}
                    className="block_filter"
                    passedDownItems={allVillages}
                    onChangeFun_={(e) => {
                      let getFilters = JSON.parse(
                        localStorage.getItem("filters")
                      );
                      getFilters = {
                        ...getFilters,
                        villageName: e,
                      };
                      localStorage.setItem(
                        "filters",
                        JSON.stringify(getFilters)
                      );
                      setSelectedValueForVillageFilter(e);
                    }}
                    selectedValue={selectedValueForVillageFilter}
                    isDisable={!selectedValueForBlockFilter}
                  />
                </div>
                {/* ) } */}
                <div className="col-md-3 col-xs-12">
                  <div style={containerStyle}>
                    <div
                      style={{
                        ...buttonStyle,
                        ...(selectedToggle === "all"
                          ? selectedStyle
                          : unselectedStyle),
                      }}
                      onClick={() => handleToggleChange("all")}
                    >
                      All Villages
                    </div>
                    <div
                      style={{
                        ...buttonStyle,
                        ...(selectedToggle === "live"
                          ? selectedStyle
                          : unselectedStyle),
                      }}
                      onClick={() => handleToggleChange("live")}
                    >
                      Live Villages (4,47,383)
                    </div>
                  </div>
                </div>
              </div>
              {(searchValue ||
                selectedValueForStateFilter ||
                selectedValueForDistrictFilter ||
                selectedValueForBlockFilter ||
                selectedValueForVillageFilter ||
                searchValue) && (
                <div className="row">
                  <div className="col-3" style={{ marginTop: "1%" }}>
                    <button
                      className="btn"
                      style={{
                        backgroundColor: "#3CAF5C",
                        color: "#fff",
                      }}
                      onClick={() => {
                        clearState();
                        fetchVillage();
                      }}
                    >
                      Clear
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div
            className="th-village-height-scroll pr-md-3 pr-0"
            id="anchor"
            style={{ overflowX: "hidden" }}
          >
            {searchClicked && searchWord.length !== 0 ? (
              <div className="pb-3 d-flex justify-content-between">
                <div>
                  Search results for: "<b>{searchWord}</b>"
                </div>
                <div
                  onClick={onReset}
                  className="th-btn-green-outline th-fw-600 th-pointer px-3 ml-4"
                >
                  Reset
                </div>
              </div>
            ) : null}
            {searchClicked && searchWord.length !== 0 ? null : (
              <div className="row"></div>
            )}

            {villageList.length > 0 ? (
              <div className="row px-2 mobile-scroll">
                <ReactPlaceholder
                  ready={villageList.length > 0}
                  customPlaceholder={awesomePlaceholder}
                >
                  {villageList.map((hit, index) => (
                    <div
                      className="col-lg-3 col-md-6 pb-3 px-1"
                      key={index}
                      ref={
                        index === villageList.length - 1
                          ? lastVillageElementRef
                          : null
                      }
                    >
                      {hit?.live ? (
                        <Link
                          to={{
                            pathname: `village-details/${hit.village_key}`,
                            state: {
                              villageId: hit.village_key,
                            },
                          }}
                        >
                          <VillageCard
                            village={hit}
                            village_name={hit?.village_name}
                            state={hit?.state_name}
                            cover={hit?.thumbnail}
                            thumbnaiId={hit?.thumbnaiId}
                            live={hit?.live}
                            isGoodStories={hit?.isGoodStories}
                          />
                        </Link>
                      ) : (
                        <VillageCard
                          village={hit}
                          village_name={hit?.village_name}
                          state={hit?.state_name}
                          cover={hit?.thumbnail}
                          thumbnaiId={hit?.thumbnaiId}
                          live={hit?.live}
                        />
                      )}
                    </div>
                  ))}
                </ReactPlaceholder>
              </div>
            ) : (
              <>
                {!isLoading ? (
                  <Typography variant="h1">No Data Found</Typography>
                ) : (
                  <div className="col-12 text-center py-3">
                    <CircularProgress />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </MyLayout>
    </PageContainer>
  );
};

export default Village;

const buttonStyle = {
  borderRadius: "20px",
  textTransform: "uppercase",
  fontWeight: "bold",
  fontSize: "0.75rem",
  padding: "8px 16px",
  margin: "0 2px",
  border: "none",
  outline: "none",
  cursor: "pointer",
  userSelect: "none",
  transition: "background-color 0.3s, color 0.3s",
};

const selectedStyle = {
  background: "linear-gradient(45deg, #4caf50 30%, #81c784 90%)",
  color: "#fff",
};

const unselectedStyle = {
  backgroundColor: "#fff",
  color: "#757575",
};

const containerStyle = {
  display: "flex",
  borderRadius: "20px",
  backgroundColor: "#ffffff",
  width: "300px",
  marginLeft: window.innerWidth > 768 ? "35%" : "6%",
  marginTop: window.innerWidth < 786 ? "10px" : "0",
};

const divStyle = {
  height: "70vh",
  width: "100%",
  position: "relative",
  left: "-5%",
};

const divMobStyle = {
  height: "100vh", // Make sure the body takes the full viewport height
  width: "100%",
  position: "relative",
  top: "-3%",
};

const awesomePlaceholder = (
  <>
    <div className="d-flex col-12 my-awesome-placeholder my-2">
      <div className="col-3 mx-3">
        <RectShape color="#dadada" style={{ height: "60px" }} />
      </div>
      <div className="col-3 mx-3">
        <RectShape color="#dadada" style={{ height: "60px" }} />
      </div>
      <div className="col-3 mx-3">
        <RectShape color="#dadada" style={{ height: "60px" }} />
      </div>
    </div>
    <div className="d-flex col-12 my-awesome-placeholder my-2">
      <div className="col-3 mx-3">
        <RectShape color="#dadada" style={{ height: "60px" }} />
      </div>
      <div className="col-3 mx-3">
        <RectShape color="#dadada" style={{ height: "60px" }} />
      </div>
      <div className="col-3 mx-3">
        <RectShape color="#dadada" style={{ height: "60px" }} />
      </div>
    </div>
    <div className="d-flex col-12 my-awesome-placeholder my-2">
      <div className="col-3 mx-3">
        <RectShape color="#dadada" style={{ height: "60px" }} />
      </div>
      <div className="col-3 mx-3">
        <RectShape color="#dadada" style={{ height: "60px" }} />
      </div>
      <div className="col-3 mx-3">
        <RectShape color="#dadada" style={{ height: "60px" }} />
      </div>
    </div>
    <div className="d-flex col-12 my-awesome-placeholder my-2">
      <div className="col-3 mx-3">
        <RectShape color="#dadada" style={{ height: "60px" }} />
      </div>
      <div className="col-3 mx-3">
        <RectShape color="#dadada" style={{ height: "60px" }} />
      </div>
      <div className="col-3 mx-3">
        <RectShape color="#dadada" style={{ height: "60px" }} />
      </div>
    </div>
  </>
);
