import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import firebase from "../../../firebase";
import ToastContainer from "react-bootstrap/ToastContainer";
import Toast from "react-bootstrap/Toast";
import styled from "styled-components";

import MyDesktop from "../../Components/MyComponents/MyDesktop";
import MyMobile from "../../Components/MyComponents/MyMobile";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import LogoWhite from "../../../App/Assets/img/HeaderLogos/LogoWhite.svg";
import mocLogoW from "../../../App/Assets/img/HeaderLogos/mocLogoW.svg";
import IgncaLogoW from "../../../App/Assets/img/HeaderLogos/Ignca-white.svg";
import akamLogoW from "../../../App/Assets/img/HeaderLogos/akamLogoW.svg";
import BetiLogo from "../../../App/Assets/img/HeaderLogos/beti_logo.png";
import mocLogo from "../../../App/Assets/img/HeaderLogos/mocLogoWhite.svg";
import mocLogoBlack from "../../../App/Assets/img/HeaderLogos/mocLogo.svg";
import mgmdLogo from "../../../App/Assets/img/HeaderLogos/mgmdLogoWhite.svg";
import akamLogo from "../../../App/Assets/img/HeaderLogos/akamLogo.svg";
import IgncaLogo from "../../../App/Assets/img/HeaderLogos/IgncaLogoWhite.svg";
import IgncaLogoBlack from "../../../App/Assets/img/HeaderLogos/IgncaLogo.png";
import LogoBlack from "../../../App/Assets/img/HeaderLogos/LogoBlack.svg";
import g20 from "../../../App/Assets/img/General/g20.png";
import AvatarDefault from "../../Assets/img/General/AvatarDefault.jpg";
import "./header.scss";
import SideHeader from "../SideHeader";
import Footer from "../Footer";
import axios from "axios";
import DownloadButton from "../Downloadcomponent";

class Header extends Component {
  state = {
    drawerOpen: false,
    isDropDown: false,
    fireuser: null,
    loadingLanguage: true,
    width: "",
    user_data: {},
    showToaster: false,
    isUser: localStorage.getItem("userType"),
    showProfileModal: false,
    profileValues: {
      phone: "",
      designation: "",
    },
    phoneError: "",
  };

  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
    this.fetchStatData();
    window.addEventListener("resize", this.updateWindowDimensions);
    if (localStorage.getItem("user")) {
      let user = JSON.parse(localStorage.getItem("userdata"));
      this.setState({ fireuser: user });
      this.checkProfileCompletion();
    }
    document
      .querySelector(".th-90-height-scroll")
      ?.addEventListener("scroll", this.handleScroll);
    document
      .querySelector("body")
      ?.addEventListener("scroll", this.handleScroll);
    window.addEventListener("storage", this.handleStorage);
  }

  checkProfileCompletion = async () => {
    const user = firebase.auth().currentUser;
    if (user) {
      const db = firebase.firestore();
      const userDoc = await db.collection("users").doc(user.uid).get();
      const userData = userDoc.data();
      if (userData && !userData.is_profile_completed) {
        this.setState({ showProfileModal: true });
      }
    }
  };

  designationOptions = [
    "Member of Panchayat",
    "School/ College Teacher",
    "Cultural Expert",
    "BDO or any Govt. Officer",
    "Local/ General Public"
  ];

  checkPhoneExists = async (phone) => {
    const db = firebase.firestore();
    const snapshot = await db.collection("users").where("values.phone", "==", phone).get();
    return !snapshot.empty;
  };

  handleProfileModalSubmit = async () => {
    const { profileValues } = this.state;
    const user = firebase.auth().currentUser;

    if (user) {
      const db = firebase.firestore();
      try {
        // Check if phone number already exists
        const phoneExists = await this.checkPhoneExists(profileValues.phone);
        if (phoneExists) {
          this.setState({ phoneError: "This phone number is already registered" });
          return;
        }

        await db.collection("users").doc(user.uid).update({
          values: {
            ...profileValues,
            email: user.email,
          },
          is_profile_completed: true,
        });

        this.setState({ showProfileModal: false, phoneError: "" });
      } catch (error) {
        console.error("Error updating profile:", error);
        this.setState({ phoneError: "An error occurred. Please try again." });
      }
    }
  };

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener("resize", this.updateWindowDimensions);
    document
      .querySelector(".th-90-height-scroll")
      ?.removeEventListener("scroll", this.handleScroll);
    document
      .querySelector("body")
      ?.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("storage", this.handleStorage);
  }

  updateWindowDimensions = () => {
    if (this._isMounted) {
      this.setState({ width: window.innerWidth });
    }
  };

  handleScroll = (event) => {
    if (document.querySelector("div#dropToggle")?.classList?.contains("show")) {
      document.querySelector(".dropdown-toggle.btn").click();
    }
  };

  handleStorage = () => {
    try {
      let user = JSON.parse(localStorage.getItem("userdata"));
      if (this._isMounted) {
        this.setState({ fireuser: user });
      }
    } catch (e) {
      console.error("Error parsing userdata from localStorage", e);
    }
  };

  handleLanguage = () => {
    if (this._isMounted) {
      this.setState({ isDropDown: !this.state.isDropDown });
    }
  };

  handleLogout = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        this.setState({ showToaster: true });
        setTimeout(() => {
          localStorage.clear();
          localStorage.removeItem("user");
          localStorage.removeItem("userdata");
          localStorage.removeItem("userType");
          localStorage.removeItem("village-visited");
          window.location.href = "/";
          if (this._isMounted) {
            this.setState({ showToaster: false });
          }
        }, 500);
      });
  };

  fetchStatData = async () => {
    if (
      localStorage.getItem("user") &&
      JSON.parse(localStorage.getItem("user")).uid
    ) {
      await firebase
        .firestore()
        .collection("users")
        .doc(JSON.parse(localStorage.getItem("user")).uid)
        .get()
        .then(async (doc) => {
          let docData = doc.data();
          if (docData?.avatar) {
            try {
              const url = `https://asia-south1-mgmd-356213.cloudfunctions.net/getSignedURLFromCF?url=https://d296j4bbogbdom.cloudfront.net/fit-in/100x100/images/${docData.avatar}`;
              const response = await axios.get(url);
              const data = response?.data?.data;
              docData.avatar = data;
              if (this._isMounted) {
                this.setState({ user_data: docData });
              }
            } catch (error) {
              console.error("Error fetching image data:", error);
            }
          } else {
            if (this._isMounted) {
              this.setState({ user_data: docData });
            }
          }
        });
    }
  };

  handleName = () => {
    let user = JSON.parse(localStorage.getItem("userdata"));
    if (this._isMounted) {
      this.setState({ fireuser: user });
    }
  };
  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <ToastContainer position="top-end" className="p-3">
          <Toast
            onClose={() => this.setState({ showToaster: false })}
            show={this.state.showToaster}
            className="text-white th-bg-primary"
          >
            <Toast.Body>
              {this.state.isUser === "artist" ? "Artist" : "User"} logged out
              successfully.
            </Toast.Body>
          </Toast>
        </ToastContainer>

        <Modal
          show={this.state.showProfileModal}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header>
            <Modal.Title>Complete Your Profile</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="tel"
                  placeholder="Enter your phone number"
                  value={this.state.profileValues.phone}
                  onChange={(e) => this.setState({ profileValues: { ...this.state.profileValues, phone: e.target.value }, phoneError: "" })}
                  isInvalid={!!this.state.phoneError}
                />
                <Form.Control.Feedback type="invalid">
                  {this.state.phoneError}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Designation</Form.Label>
                <Form.Control
                  as="select"
                  value={this.state.profileValues.designation}
                  onChange={(e) => this.setState({ profileValues: { ...this.state.profileValues, designation: e.target.value } })}
                >
                  <option value="">Select Designation</option>
                  {this.designationOptions.map((option, index) => (
                    <option key={index} value={option}>{option}</option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.handleProfileModalSubmit}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
        {/* DESKTOP HEADER SECTION */}
        <MyDesktop>
          <div
            className={
              "fixed-top " + (this.props.transparent ? "" : "bg-white")
            }
            style={{ height: "auto" }}
          >
            <>
              {window.location.pathname != "/signup" &&
                window.location.pathname != "/login" && (
                  <>
                    <Dropdown
                      style={{ position: "fixed", right: 15, top: 18 }}
                      id="dropToggle"
                      onClick={() => this.handleName()}
                    >
                      <Dropdown.Toggle
                        className="user_login_dots"
                        style={{
                          backgroundColor: "transparent",
                          border: "none",
                          padding: 0,
                          marginTop: 10,
                        }}
                      >
                        {this.props.transparent ? (
                          <i
                            className="fa fa-bars"
                            aria-hidden="true"
                            style={{ color: "#FFFFFF", fontSize: "1.5rem" }}
                          ></i>
                        ) : (
                          <i
                            className="fa fa-bars"
                            aria-hidden="true"
                            style={{ color: "#000", fontSize: "1.5rem" }}
                          ></i>
                        )}
                      </Dropdown.Toggle>

                      <Dropdown.Menu
                        style={{
                          minWidth: "10rem",
                          minHeight: "6rem",
                          textAlign: "center",
                          zIndex: "9999",
                        }}
                      >
                        {localStorage.getItem("user") &&
                          this.state.fireuser?.name && (
                            <Link
                              className="col "
                              to={
                                localStorage.getItem("user") &&
                                localStorage.getItem("userType") == "artist"
                                  ? "/artist-profile"
                                  : "/profile"
                              }
                            >
                              <div>
                                <ProfileImage>
                                  <img
                                    src={
                                      this.state.user_data?.avatar
                                        ? this.state.user_data?.avatar
                                        : AvatarDefault
                                    }
                                    onError={(e) => {
                                      e.target.src = AvatarDefault;
                                    }}
                                    style={{
                                      borderRadius: "50%",
                                      width: "100%",
                                      height: "100%",
                                    }}
                                  />
                                </ProfileImage>
                              </div>
                              <div className="col px-3 text-capitalize py-2">
                                {this.state.fireuser?.name
                                  ? this.state.fireuser?.name.split(" ")[0]
                                  : "Anonymous"}
                              </div>
                            </Link>
                          )}

                        {/* <div>
                          <Link className="col " to="/about">
                            <div className="py-2">About</div>
                          </Link>
                        </div> */}
                        {window.innerWidth > 700 &&
                          window.innerWidth < 1132 &&
                          this.state.fireuser?.name && (
                            <div>
                              <Link className="col " to="/home">
                                <div className="py-2">Dashboard</div>
                              </Link>
                            </div>
                          )}
                        {window.innerWidth > 700 &&
                          window.innerWidth < 1132 && (
                            <div>
                              <Link className="col " to="/terms">
                                <div className="py-2">T&C</div>
                              </Link>
                            </div>
                          )}
                        {window.innerWidth > 700 &&
                          window.innerWidth < 1132 && (
                            <div>
                              <Link className="col " to="/disclaimer">
                                <div className="py-2">Disclaimer</div>
                              </Link>
                            </div>
                          )}
                        {window.innerWidth > 700 &&
                          window.innerWidth < 1132 && (
                            <div>
                              <Link className="col " to="/privacy">
                                <div className="py-2">Privacy Policy</div>
                              </Link>
                            </div>
                          )}
                        {!localStorage.getItem("user") &&
                          !this.state.fireuser?.name && (
                            <Link className="col " to="/login">
                              <div
                                className="col btn px-3 py-1 th-br-5 th-nowrap my-2 th-bg-primary About"
                                style={{
                                  border: "none",
                                  width: 80,
                                }}
                              >
                                Log In
                              </div>
                            </Link>
                          )}
                        {this.state.fireuser?.name ? (
                          <div
                            onClick={(e) => this.handleLogout()}
                            className={`col btn px-3 py-1 th-br-5 mt-2 th-pointer th-bg-primary About ${
                              this.props.transparent ? "th-black" : "th-black"
                            }`}
                            style={{ border: "none" }}
                          >
                            Logout
                          </div>
                        ) : (
                          <Link className="col " to="/signup">
                            <div
                              className="col px-3 btn py-1 th-br-5 text-nowrap th-bg-primary About"
                              style={{
                                border: "none",
                                width: 80,
                              }}
                            >
                              Sign Up
                            </div>
                          </Link>
                        )}
                        {/* */}
                      </Dropdown.Menu>
                    </Dropdown>
                  </>
                )}
            </>
            <div className="container-fluid">
              <div className="row mb-2 py-1">
                <div
                  className={`col-12 ${
                    this.props.transparent
                      ? "header-transparent-part"
                      : "header-part"
                  }`}
                >
                  {this.props.transparent ? (
                    <div className="d-flex align-items-center mt-2">
                      <div
                        className="my-auto py-2 logoSection"
                        style={{ marginLeft: "1%" }}
                      >
                        <img
                          alt="mocLogoW"
                          className="my-auto img-fluid mx-3 cursor-pointer tablet-responsive last-image"
                          src={mgmdLogo}
                          style={{ height: 40 }}
                        />
                        <Link to="/explore">
                          <img
                            alt="mocLogoW"
                            className="my-auto img-fluid mx-3 cursor-pointer tablet-responsive last-image"
                            src={mocLogo}
                            style={{ height: 40 }}
                          />
                        </Link>
                        {/* <Link to="/explore">
                          <img
                            alt="LogoBlack"
                            className="my-auto img-fluid mx-2 tablet-responsive"
                            src={g20}
                            style={{ height: 40 }}
                          />
                        </Link> */}
                      </div>

                      <a href="https://ignca.gov.in/">
                        <img
                          alt="logo"
                          className="my-auto img-fluid mx-2 tablet-responsive"
                          src={IgncaLogo}
                          style={{
                            height: 40,
                          }}
                        />
                      </a>
                      {/* <div
                        className="row align-items-center my-2 py-2"
                        style={{ marginRight: "1%" }}
                      ></div> */}
                    </div>
                  ) : (
                    <div className="d-flex justify-content-between align-items-center">
                      <div
                        className="my-auto py-2 logoSection"
                        style={{ marginLeft: "1%" }}
                      >
                        {/* <img
                          alt="mocLogoW"
                          className="my-auto img-fluid mx-3 cursor-pointer tablet-responsive last-image"
                          src={mgmdLogo}
                          style={{ height: 40 }}
                        /> */}
                        <Link to="/explore">
                          <img
                            alt="mocLogo"
                            className="my-auto img-fluid mx-2 tablet-responsive"
                            src={mocLogoBlack}
                            style={{
                              height: 35,
                            }}
                          />
                        </Link>
                        <Link to="/explore">
                          <img
                            alt="beti"
                            className="my-auto img-fluid mx-2 tablet-responsive"
                            src={BetiLogo}
                            style={{
                              height: 40,
                            }}
                          />
                        </Link>
                      </div>
                      {window.innerWidth > 1130 ? <SideHeader /> : <Footer />}
                      {/* <div
                        className="row align-items-center my-2 py-2"
                        style={{ marginRight: "1%" }}
                      ></div> */}
                      <div style={{ marginRight: "2rem" }}>
                        <a href="https://ignca.gov.in/">
                          <img
                            alt="IgncaLogo"
                            className="my-auto img-fluid mx-2 tablet-responsive"
                            src={IgncaLogoBlack}
                            style={{
                              height: 40,
                              paddingTop: 5,
                              paddingRight: 10,
                            }}
                          />
                        </a>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </MyDesktop>
        {/* MONILE HEADER SECTION */}

        <MyMobile>
          <div
            className={
              "fixed-top " + (this.props.transparent ? "" : "bg-white")
            }
          >
            {window.location.pathname.includes("/signup") ||
            window.location.pathname.includes("/login") ? (
              ""
            ) : (
              <Dropdown
                id="dropToggle"
                style={{ position: "absolute", right: 9, top: 10 }}
              >
                <Dropdown.Toggle
                  className="user_login_dots"
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    padding: 0,
                    margin: `${
                      window.location.pathname.includes("/explore")
                        ? "7px 0px"
                        : "7px 0px"
                    }`,
                  }}
                >
                  {this.props.transparent ? (
                    <i
                      className="fa fa-bars"
                      aria-hidden="true"
                      style={{ color: "#FFFFFF", fontSize: "1rem" }}
                    ></i>
                  ) : (
                    <i
                      className="fa fa-bars"
                      aria-hidden="true"
                      style={{ color: "#000", fontSize: "1rem" }}
                    ></i>
                  )}
                </Dropdown.Toggle>
                <Dropdown.Menu
                  style={{
                    minWidth: "4rem",
                    textAlign: "center",
                  }}
                >
                  <Dropdown.Item>
                    {this.state.fireuser?.name && (
                      <Link
                        className="col"
                        to={
                          localStorage.getItem("user") &&
                          localStorage.getItem("userType") == "artist"
                            ? "/artist-profile"
                            : "/profile"
                        }
                      >
                        <ProfileImage>
                          <img
                            src={
                              this.state.user_data?.avatar
                                ? this.state.user_data?.avatar
                                : AvatarDefault
                            }
                            onError={(e) => {
                              e.target.src = AvatarDefault;
                            }}
                            style={{
                              borderRadius: "50%",
                              width: "100%",
                              height: "100%",
                            }}
                          />
                        </ProfileImage>

                        <div className="col px-3 text-capitalize py-2">
                          {this.state.fireuser?.name
                            ? this.state.fireuser?.name.split(" ")[0]
                            : "Anonymous"}
                        </div>
                      </Link>
                    )}
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <DownloadButton />
                  </Dropdown.Item>
                  {/* <Dropdown.Item>
                    <div>
                      <Link className="col " to="/about">
                        <div className="py-2">About</div>
                      </Link>
                    </div>
                  </Dropdown.Item> */}

                  <Dropdown.Item>
                    {this.state.fireuser?.name && (
                      <Link className="col " to="/home">
                        <div className="py-2">Dashboard</div>
                      </Link>
                    )}
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <Link className="col " to="/terms">
                      <div className="py-2">T&C</div>
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <Link className="col " to="/disclaimer">
                      <div className="py-2">Disclaimer</div>
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <Link className="col " to="/privacy">
                      <div className="py-2">Privacy Policy</div>
                    </Link>
                  </Dropdown.Item>

                  <Dropdown.Item>
                    {!this.state.fireuser?.name && (
                      <Link className="col" to="/login">
                        <div
                          className="col  btn px-3 py-1 th-br-5 th-nowrap th-bg-primary"
                          style={{
                            border: "none",
                            width: 80,
                          }}
                        >
                          Log In
                        </div>
                      </Link>
                    )}
                  </Dropdown.Item>
                  <Dropdown.Item>
                    {this.state.fireuser?.name ? (
                      <div
                        onClick={(e) => this.handleLogout()}
                        className={`col btn px-3 py-1 th-br-5 th-pointer th-bg-primary ${
                          this.props.transparent ? "th-black" : "th-black"
                        }`}
                        style={{ border: "none" }}
                      >
                        Logout
                      </div>
                    ) : (
                      <Link className="col" to="/signup">
                        <div
                          className="col px-3 btn py-1 th-br-5 text-nowrap th-bg-primary"
                          style={{ border: "none", width: 80 }}
                        >
                          Sign Up
                        </div>
                      </Link>
                    )}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
            <div
              className="container"
              style={{ maxWidth: this.state.width <= 767 ? "unset" : "" }}
            >
              <div
                className={`row align-items-center ${
                  this.props.transparent ? "header-transparent-part" : ""
                }`}
              >
                {this.props.transparent ? (
                  <div className=" col-12 py-3 d-flex align-item-center mt-2">
                    <div className="my-auto text-nowrap d-flex">
                      <img
                        alt="mocLogoW"
                        className="my-auto img-fluid mx-3 cursor-pointer tablet-responsive last-image"
                        src={mgmdLogo}
                        style={{ height: 40 }}
                      />
                      <Link to="/explore">
                        <img
                          alt="mocLogoW"
                          className="my-auto img-fluid mx-1"
                          src={mocLogo}
                          style={{ height: "27px" }}
                        />
                      </Link>
                      {/* <Link to="/explore">
                        <img
                          alt="LogoWhite"
                          className="my-auto img-fluid mx-1"
                          src={g20}
                          style={{ height: "27px" }}
                        />
                      </Link> */}
                      <Link to="/explore">
                        <img
                          alt="akamLogoW"
                          className="my-auto mx-1 img-fluid"
                          src={BetiLogo}
                          style={{ height: "27px" }}
                        />
                      </Link>
                      <Link to="/explore">
                        <img
                          alt="logo"
                          className="my-auto img-fluid mx-1 tablet-responsive"
                          src={IgncaLogo}
                          style={{ height: "27px" }}
                        />
                      </Link>
                    </div>
                  </div>
                ) : (
                  <div className=" col-12 py-3 d-flex align-item-center">
                    <div className="my-auto text-nowrap">
                      <img
                        alt="mocLogoW"
                        className="my-auto img-fluid mx-3 cursor-pointer tablet-responsive last-image"
                        src={mocLogoBlack}
                        style={{ height: 40 }}
                      />
                      <Link to="/explore">
                        <img
                          alt="mocLogo"
                          className="my-auto img-fluid mx-1"
                          src={this.props.transparent ? mocLogo : mocLogo}
                          style={{ height: "24px" }}
                        />
                      </Link>
                      {/* <Link to="/explore">
                        <img
                          alt="Logo"
                          className="my-auto mx-1  img-fluid "
                          src={this.props.transparent ? g20 : g20}
                          style={{ height: "23px" }}
                        />
                      </Link> */}
                      <Link to="/explore">
                        <img
                          alt="akamLogo"
                          className="my-auto img-fluid mx-1"
                          src={BetiLogo}
                          style={{ height: "36px" }}
                        />
                      </Link>
                      <a href="https://ignca.gov.in/">
                        <img
                          alt="IgncaLogo"
                          className="my-auto img-fluid mx-1 tablet-responsive"
                          src={IgncaLogoBlack}
                          style={{ height: 35, paddingRight: 0 }}
                        />
                      </a>
                    </div>
                    <></>
                  </div>
                )}
              </div>
            </div>
          </div>
        </MyMobile>
      </React.Fragment>
    );
  }
}
const ProfileImage = styled.div`
  margin: 0 auto;
  height: 3rem;
  width: 3rem;
  border: solid 1px #f0f0f3;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  justify-self: center;
  align-self: center;
  box-shadow: -4.4012px -4.4012px 17.6048px #ffffff,
    4.4012px 4.4012px 17.6048px rgba(174, 174, 192, 0.5);
  grid-row: 1;
`;
export default withRouter(Header);
