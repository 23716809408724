import React, { useEffect, useState } from "react";
import firebase from "../../../firebase";
import MyLayout from "../../Components/MyLayout/index";
import MyDesktop from "../../Components/MyComponents/MyDesktop";
import MyMobile from "../../Components/MyComponents/MyMobile";
import { Link } from "react-router-dom";
import { Box, Dialog, DialogContent, Grid } from "@mui/material";
import axios from "axios";
import DefaultImage from "../../Assets/img/General/default.png";
import CustomSearchBox from "../Village/CustomSearchBar";
import StoriesWebBanner from "../../Assets/img/background/stories_web_banner.png";
import StoryMobBanner from "../../Assets/img/background/stories_mob_banner.png";
import Typography from "@mui/material/Typography";
import StoriesWebImage from "../../Assets/img/background/stories_web_image.png";
import StoriesMobImage from "../../Assets/img/background/stories_mob_image.png";
import PageContainer from "../../Components/Container";

const themes = [
  "All",
  // "Traditional Food",
  // "Traditional Attires",
  // "Traditional Ornaments",
  // "Arts and Crafts",
  // "Prominent Artists",
  // "Famous Personalities",
  // "Community led Initiatives",
  "Heritage Landmarks",
  // "Environmental Practices and Sustainability",
  // "Spiritual Significance",
  // "Education",
  // "Traditional Belief",
  // "Technology and Innovation",
  // "Heritage Architecture",
  "Famous personalities & Local Heroes",
  "Inspiring Contributions",
  "Local Tales",
  "Traditional Arts and Craft",
  "Traditional Festivals",
];

const GoodStories = () => {
  const [open, setOpen] = useState(false);
  const [currentStory, setCurrentStory] = useState(null);
  const [expandedCards, setExpandedCards] = useState({});
  const [villageStories, setVillageStories] = useState([]);
  const [lastVisible, setLastVisible] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [selectedTheme, setSelectedTheme] = useState("All");
  const [searchQuery, setSearchQuery] = useState("");

  const fetchStories = async (startAfterDoc = null) => {
    let db = firebase.firestore();
    let query = db
      .collection("good_stories")
      .where("village_name", "!=", "")
      .limit(51);

    if (startAfterDoc) {
      query = query.startAfter(startAfterDoc);
    }

    const docRef = await query.get();

    if (docRef.empty) {
      setHasMore(false);
      return;
    }

    const res = await Promise.all(
      docRef.docs.map(async (doc) => {
        let data = doc.data();
        const imageUrlResponse = await axios.get(
          `https://asia-south1-mgmd-356213.cloudfunctions.net/getSignedURLFromCF?url=https://d296j4bbogbdom.cloudfront.net/images/${data.image_source}`
        );
        const imageUrl = imageUrlResponse.data?.data; // Adjust this if the response is not plain text
        return {
          ...data,
          image_source: imageUrl,
        };
      })
    );

    setVillageStories((prev) => [...prev, ...res]);
    setLastVisible(docRef.docs[docRef.docs.length - 1]);
  };

  useEffect(() => {
    fetchStories();
  }, []);

  const loadMoreStories = () => {
    if (hasMore) {
      fetchStories(lastVisible);
    }
  };

  const toggleCardExpansion = (id) => {
    setExpandedCards((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const handleClickOpen = (story) => {
    setCurrentStory(story);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentStory(null);
  };

  const truncateText = (text, maxLength) => {
    if (text?.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  const handleThemeSelect = async (value) => {
    setSelectedTheme(value);
    if (!searchQuery) {
      setVillageStories([]);
      let db = firebase.firestore();
      const collectionName = db.collection("good_stories");
      if (value !== "All") {
        const querySnapshot = await collectionName
          .where("themes", "array-contains", value)
          .where("village_name", "!=", "")
          .get();
        if (!querySnapshot.empty) {
          const res = await Promise.all(
            querySnapshot.docs.map(async (doc) => {
              let data = doc.data();
              const imageUrlResponse = await axios.get(
                `https://asia-south1-mgmd-356213.cloudfunctions.net/getSignedURLFromCF?url=https://d296j4bbogbdom.cloudfront.net/images/${data.image_source}`
              );
              const imageUrl = imageUrlResponse.data?.data; // Adjust this if the response is not plain text
              return {
                ...data,
                image_source: imageUrl,
              };
            })
          );
          setVillageStories(res);
        }
      } else {
        fetchStories();
      }
    } else {
      handleSearchChange(searchQuery).then();
    }
  };

  const handleSearchChange = async (value) => {
    const query = value;
    setSearchQuery(query);
    setVillageStories([]);

    if (query.length > 2) {
      // Trigger API call if query length is greater than 2
      try {
        let apiUrl = `https://asia-south1-mgmd-356213.cloudfunctions.net/goodStoriesElasticSearch?query=${query}&page=0&restrict=custom&restrictSearchableAttributes=village_name`;

        // Add theme filter if a specific theme is selected
        if (selectedTheme !== "All") {
          apiUrl += `&customFilter=themes:${selectedTheme}`;
        }

        const response = await axios.get(apiUrl);
        const res = await Promise.all(
          response.data.hits.map(async (obj) => {
            let imageName = obj.image_source;
            const imageUrlResponse = await axios.get(
              `https://asia-south1-mgmd-356213.cloudfunctions.net/getSignedURLFromCF?url=https://d296j4bbogbdom.cloudfront.net/images/${imageName}`
            );
            const imageUrl = imageUrlResponse.data?.data; // Adjust this if the response is not plain text
            return {
              ...obj,
              image_source: imageUrl,
            };
          })
        );
        console.log(res);
        setVillageStories(res);
      } catch (error) {
        console.error("Error fetching search results:", error);
      }
    }

    if (query.length === 0) {
      fetchStories();
    }
  };

  return (
    <PageContainer
      keywords="Mera Gaon Meri Dharohar Stories"
      title="Inspiring Stories of Village Heritage - Mera Gaon Meri Dharohar"
      description="Explore inspiring stories from Mera Gaon Meri Dharohar, showcasing the rich heritage and culture of Indian villages. Discover how these narratives empower communities and preserve traditions. Join us in celebrating the vibrant tapestry of village life."
      canonical="https://mgmd.gov.in/good-stories"
    >
      <MyLayout>
        <div className="col-md-12 custom-list">
          <div className="pb-3 search-content justify-content-between">
            <MyDesktop>
                   <div
        style={{
          position: "relative",
          width: "100%",
          left: "0%",
          marginBottom: "2rem",
        }}
      >
        <div
          style={{
            position: "relative",
            width: "100%",
            paddingTop: "35%",
          }}
        >
          <img
            src={StoriesWebBanner}
            style={{
              position: "absolute",
              top: 0,
              left: "-38%", 
              width: "100%",
              height: "100%",
              zIndex: "1",
            }}
          />
          <Box
            sx={{
              position: "absolute",
              zIndex: "2",
              width: "30%",
              top: "20%",
              left: "6%", 
            }}
          >
            <Typography
              variant="h4"
              color="#68401A"
              sx={{
                fontFamily: "Playfair Display, serif",
              }}
            >
              Unique Stories
            </Typography>
            <Typography
              variant="subtitle1"
              mt={5}
              color="#68401A"
              sx={{ fontFamily: "Inter, sans-serif", fontSize: "1rem" }}
            >
              Every village of India holds a story. This section features a
              diverse collection of local tales and captivating narratives.
              Browse these stories based on location or themes of your interest
              and discover them now.
              <br />
              Do you have more such stories to share? Visit the village and
              contribute now!
              <br />2 Lakh + Unique visitors | 2000+ contributions received
            </Typography>
          </Box>
          <Box
            sx={{
              position: "absolute",
              right: "-20%", 
              top: 0,
              height: "100%",
              width: "75%", 
              overflow: "hidden",
            }}
          >
            <img
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
              src={StoriesWebImage}
            />
          </Box>
        </div>

              </div>

              <div style={{ padding: '0 4%' }}>
                <Grid container spacing={2} sx={{ marginBottom: '2rem' }}>
                  <Grid item xs={12}>
                    <CustomSearchBox
                      _className=""
                      _onchangeFunc={(e) => handleSearchChange(e.target.value)}
                    />
                  </Grid>
                </Grid>
         </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  flexWrap: "wrap",
                  padding: "0 8% 0 3%",
                }}
              >
                {themes.map((value) => (
                  <div
                    style={{
                      borderRadius: "10px",
                      backgroundColor:
                        selectedTheme === value ? "#3CAF5C" : "#fff",
                      padding: "10px",
                      color: selectedTheme === value ? "#fff" : "#3CAF5C",
                      marginBottom: "2%",
                      marginLeft: "1%",
                      fontWeight: "bold",
                      cursor: "pointer",
                    }}
                    onClick={() => handleThemeSelect(value)}
                  >
                    {value === "Famous personalities & Local Heroes"
                      ? "Famous Personalities & Local Heroes"
                      : value}
                  </div>
                ))}
              </div>

              <div
                className="stories"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "20px",
                  alignItems: "flex-start",
                  marginLeft: "4%",
                }}
              >
                {villageStories.map((story) => (
                  <div
                    key={story?.id}
                    className="stories-card"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      background: "#fff",
                      borderRadius: "20px",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      width: "30%",
                      transition: "all 0.3s ease",
                    }}
                  >
                    <div className="stories-card-image">
                      <img
                        src={story?.image_source}
                        alt={story?.village_name}
                        style={{
                          width: "100%",
                          height: "200px",
                          objectFit: "cover",
                          borderRadius: "10px",
                          transition: "all 0.3s ease",
                        }}
                        onError={(e) => (e.target.src = DefaultImage)}
                      />
                    </div>
                    <div
                      className="stories-card-story"
                      style={{ marginTop: "2%", padding: "20px" }}
                    >
                      <h3
                        style={{
                          color: "#3CAF5C",
                          fontSize: "18px",
                          minHeight: "44px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {story?.tagline}
                      </h3>
                      <h4 style={{ fontSize: "16px" }}>
                        {story?.village_name}, {story?.state_name}
                      </h4>
                      <p>{truncateText(story?.one_liner, 70)}</p>
                      <div
                        className="stories-card-story-footer"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "auto",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <button
                            className="btn"
                            style={{
                              borderColor: "#3CAF5C",
                              color: "#3CAF5C",
                            }}
                            onClick={() => handleClickOpen(story)}
                          >
                            Read More
                          </button>
                        </div>
                        {story?.page_link && (
                          <Link to={story?.page_link}>
                            <button
                              className="btn"
                              style={{
                                backgroundColor: "#3CAF5C",
                                color: "#fff",
                              }}
                            >
                              Explore Village
                            </button>
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              {hasMore && selectedTheme === "All" && (
                <div style={{ textAlign: "center", marginTop: "20px" }}>
                  <button
                    className="btn"
                    style={{
                      backgroundColor: "#3CAF5C",
                      color: "#fff",
                      border: "none",
                      padding: "10px 20px",
                      borderRadius: "5px",
                    }}
                    onClick={loadMoreStories}
                  >
                    Load More
                  </button>
                </div>
              )}
            </MyDesktop>
            <MyMobile>
              {/* <div
              className="pb-3"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <h1 className="th-25 fw-700 th-fair-play text-success">
                Mera Gaon Meri Dharohar
              </h1>
              <h1 className="th-20 fw-700">Unique Stories</h1>
              <div style={{ textAlign: "center" }}>
                <p style={{ marginBottom: "0" }}>
                  Every village of India holds a story. This section features a
                  diverse collection of local tales and captivating narratives.
                  Browse these stories based on location or themes of your
                  interest and discover them now.
                </p>
                <p style={{ fontStyle: "italic", fontSize: "14px" }}>
                  Do you have more such stories to share? Visit the village and
                  contribute now! <br />2 Lakh + Unqiue visitors | 2000+
                  contributions received{" "}
                  <Link to="/explore">
                    <span style={{ color: "#3CAF5C", fontWeight: "600" }}>
                      Click to Explore.
                    </span>
                  </Link>
                </p>
              </div>
            </div> */}
              <div style={divMobStyle}>
                <Box
                  width="100%"
                  sx={{
                    position: "absolute",
                    overflow: "hidden",
                    height: "60vh",
                  }}
                >
                  <img
                    height="100%"
                    width="100%"
                    // style={{
                    //   objectFit: "contain",
                    // }}
                    src={StoriesMobImage}
                  ></img>
                </Box>
                <img
                  src={StoryMobBanner}
                  style={{
                    width: "100%",
                    position: "absolute",
                    zIndex: "1",
                    bottom: "120px",
                  }}
                />
                <Box
                  sx={{
                    position: "absolute",
                    zIndex: "2",
                    width: "90%",
                    top: "50%",
                    left: "5%",
                  }}
                >
                  <Typography variant="subtitle1" color="white">
                    Unique Stories
                  </Typography>
                  <Typography variant="subtitle2" mt={5} color="white">
                    Every village of India holds a story. This section features
                    a diverse collection of local tales and captivating
                    narratives. Browse these stories based on location or themes
                    of your interest and discover them now.
                    <br />
                    Do you have more such stories to share? Visit the village
                    and contribute now!
                    <br />2 Lakh + Unqiue visitors | 2000+ contributions
                    received
                  </Typography>
                </Box>
              </div>

              <Grid container spacing={2} padding="0 8% 2% 4%">
                <Grid item xs={12}>
                  <CustomSearchBox
                    // value={this.state.highlight}
                    _className=""
                    // _onchangeFunc={this.handleSearch}
                  />
                </Grid>
              </Grid>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  flexWrap: "wrap",
                  padding: "0 8% 0 3%",
                }}
              >
                {themes.map((value) => (
                  <div
                    style={{
                      borderRadius: "10px",
                      backgroundColor:
                        selectedTheme === value ? "#3CAF5C" : "#fff",
                      padding: "10px",
                      color: selectedTheme === value ? "#fff" : "#3CAF5C",
                      marginBottom: "2%",
                      marginLeft: "1%",
                      fontWeight: "bold",
                      cursor: "pointer",
                    }}
                    onClick={() => handleThemeSelect(value)}
                  >
                    {value}
                  </div>
                ))}
              </div>

              <div
                className="stories"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "20px",
                  alignItems: "flex-start",
                }}
              >
                {villageStories.map((story) => (
                  <div
                    key={story?.id}
                    className="stories-card"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      background: "#fff",
                      borderRadius: "20px",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      width: "100%",
                      transition: "all 0.3s ease",
                    }}
                  >
                    <div className="stories-card-image">
                      <img
                        src={story?.image_source}
                        alt={story?.village_name}
                        style={{
                          width: "100%",
                          height: "200px",
                          objectFit: "cover",
                          borderRadius: "10px",
                          transition: "all 0.3s ease",
                        }}
                        onError={(e) => (e.target.src = DefaultImage)}
                      />
                    </div>
                    <div
                      className="stories-card-story"
                      style={{ marginTop: "2%", padding: "20px" }}
                    >
                      <h3 style={{ color: "#3CAF5C", fontSize: "18px" }}>
                        {story?.tagline}
                      </h3>
                      <h4 style={{ fontSize: "16px" }}>
                        {story?.village_name}, {story?.state_name}
                      </h4>
                      <p>{truncateText(story?.one_liner, 70)}</p>
                      <div
                        className="stories-card-story-footer"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "auto",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <button
                            className="btn"
                            style={{
                              borderColor: "#3CAF5C",
                              color: "#3CAF5C",
                            }}
                            onClick={() => handleClickOpen(story)}
                          >
                            Read More
                          </button>
                        </div>
                        {story?.page_link && (
                          <Link to={story?.page_link}>
                            <button
                              className="btn"
                              style={{
                                backgroundColor: "#3CAF5C",
                                color: "#fff",
                              }}
                            >
                              Explore Village
                            </button>
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              {hasMore && selectedTheme === "All" && (
                <div style={{ textAlign: "center", marginTop: "20px" }}>
                  <button
                    className="btn"
                    style={{
                      backgroundColor: "#3CAF5C",
                      color: "#fff",
                      border: "none",
                      padding: "10px 20px",
                      borderRadius: "5px",
                    }}
                    onClick={loadMoreStories}
                  >
                    Load More
                  </button>
                </div>
              )}
            </MyMobile>
          </div>
        </div>
        <Dialog onClose={handleClose} open={open} maxWidth="md" fullWidth>
          <DialogContent>
            {currentStory && (
              <>
                <img
                  src={currentStory?.image_source}
                  alt={currentStory?.village_name}
                  onError={(e) => (e.target.src = DefaultImage)}
                  style={{
                    width: "100%",
                    height: "400px",
                    objectFit: "cover",
                    borderRadius: "10px",
                  }}
                />
                <h4 style={{ fontSize: "18px", marginTop: "20px" }}>
                  {currentStory?.village_name}
                </h4>
                <p>{currentStory?.state_name}</p>
                <p style={{ color: "#3CAF5C" }}>{currentStory?.tagline}</p>
                <p>{currentStory?.content}</p>
              </>
            )}
          </DialogContent>
        </Dialog>
      </MyLayout>
    </PageContainer>
  );
};

export default GoodStories;

const divStyle = {
  height: window.innerWidth < 1200 ? "80vh" : "70vh",
  width: "100%",
  position: "relative",
  left: "-5%",
};

const divMobStyle = {
  height: "100vh",
  width: "100%",
  position: "relative",
  top: "-3%",
};
