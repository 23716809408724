import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
// import vid from "./assets/intro_vid.mp4";
import intro_pic from "./assets/intro_pic.jpg";
import brown_intro from "./assets/brown_intro.jpg";
import qutub_poster from "./assets/qutubminar1.png";
// import qutubminarvid from "./assets/qutubminar.mp4";
import audio from "./assets/audio.mp3";
import styled, { keyframes } from "styled-components";
import { slideInDown } from "react-animations";
import "./index.css";
import firebase from "../../../firebase";
import Header2 from "../../Components/Header2";
import { useRef } from "react";
import PageContainer from "../../Components/Container";
const slideInAnimation = keyframes`${slideInDown}`;

const SlideInAnimation = styled.div`
  animation: 0.5s ${slideInAnimation};
`;

function Show() {
  const audioRef = useRef(null);
  const [screenSize, setScreenSize] = useState("");
  const [bg, setBg] = useState("./loop.jpg");
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [showVid, setShowVid] = useState(true);
  const [hideMain, setHideMain] = useState(false);
  const [showHome, setShowHome] = useState(true);
  const [showSwipe, setShowSwipe] = useState(false);
  const [live, setLive] = useState("live");
  const [listening, setListening] = useState(false);

  useEffect(() => {
    setScreenSize(window.innerWidth);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setShowVid(false);
    }, 8000);
  }, []);

  const onLoadedData = () => {
    setIsVideoLoaded(true);
  };

  const checkAudio = () => {
    firebase
      .firestore()
      .collection("show")
      .get()
      .then((res) => {
        let data = res?.docs[0].data();
        if (data.live) {
          if (audioRef.current) {
            const timestamp = data.timestamp; // Assuming 'timestamp' is a Firestore Timestamp field

            // Calculate the time difference in seconds
            const currentTime = new Date().getTime();
            const serverTime = timestamp.toDate().getTime();
            const timeDifferenceInSeconds = Math.floor(
              (currentTime - serverTime) / 1000
            );
            if (timeDifferenceInSeconds) {
              audioRef.current.currentTime = timeDifferenceInSeconds;
              audioRef.current.play();
              setListening(true);
            }
          }
        } else {
          setLive(false);
        }
      });
  };
  return (
    <PageContainer
      keywords="Cultural Events, Heritage Shows"
      title="MGMD Show | Experience Cultural Events and Village Heritage"
      description="Explore live shows and events showcasing India’s cultural and village heritage. Join us to witness traditional practices and village stories come alive through performances."
    >
      {showVid ? (
        <>
          <img
            src={intro_pic}
            className="video-thumb tiny"
            alt="thumb"
            style={{ opacity: isVideoLoaded ? 0 : 1 }}
          />
          {/* <video
            id="background-video"
            onLoadedData={onLoadedData}
            autoPlay
            muted
            playsinline
            poster={brown_intro}
            style={{ opacity: isVideoLoaded ? 1 : 0 }}
          >
            <source src={vid} type="video/mp4" />
          </video> */}
        </>
      ) : (
        <div
          className="img-fluid main-cont"
          style={{
            backgroundPosition: "top",
            backgroundImage: `url(${bg})`,
            backgroundSize: "cover",
            textAlign: "center",
            backgroundRepeat: "no-repeat",
            backgroundColor: "#040302",
            fontFamily: "sans-serif",
            // overflowY: "scroll",
            overflowY: showSwipe ? "hidden" : "auto",
            color: "#C1A56E",
            height: "100vh ",
            // height: "auto ",
            position: "relative",
            // filter:  "blur(10px)",
          }}
        >
          <Header2 transparent={true} />

          <div className=" align-items-center container-lg" id="main6">
            {showHome && (
              <>
                <Row className="main_content d-flex  align-items-center">
                  <Col lg={4} md={12}>
                    <SlideInAnimation>
                      <p className="digital_intro text-nowrap">
                        Don’t miss! All eyes
                        <br /> towards Qutub Minar.
                      </p>
                      <p className="digital_text">
                        {" "}
                        Projection Mapping Show 8:00 PM onwards.
                        <br /> Visible from distance.
                      </p>
                      <div className="pledge_div">
                        <Button
                          className="pledge_btn  font_text"
                          onClick={() => {
                            checkAudio();
                          }}
                        >
                          {listening ? "Listening" : "Listen Audio"}
                        </Button>
                      </div>
                      <br />
                      {live == false && (
                        <p
                          style={{ color: "#ffa800" }}
                          className="digital_text mx-2"
                        >
                          {" "}
                          Show not started yet !
                        </p>
                      )}
                      <div style={{ width: "20px", height: "20px" }}>
                        <audio ref={audioRef} src={audio} autoplay />
                      </div>
                    </SlideInAnimation>
                  </Col>

                  <Col lg={2} md={12}>
                    <div
                      className={
                        !hideMain ? "qutub_img img-fluid bottomcenter" : "hide"
                      }
                    >
                      {/* <video
                        autoPlay
                        muted
                        loop={true}
                        poster={qutub_poster}
                        playsInline
                        width="250"
                      >
                        <source src={qutubminarvid} type="video/webm" />
                      </video> */}
                    </div>
                  </Col>
                  <Col lg={5}></Col>
                </Row>
              </>
            )}
          </div>
        </div>
      )}
    </PageContainer>
  );
}

export default Show;
