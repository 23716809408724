import { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Alert, ModalBody, Modal, Button } from "react-bootstrap";
import firebase from "../../../firebase";
import MyLayout from "../../Components/MyLayout/index";
import MyDesktop from "../../Components/MyComponents/MyDesktop";
import MyMobile from "../../Components/MyComponents/MyMobile";

import OnboardingImage from "../../Assets/img/background/home_bg.jpg";
import SingBG from "../../Assets/img/background/signin_m.jpg";
import closeLogo from "../../Assets/img/icons/close.svg";
import InfoIcon from "../../Assets/img/LoginSignupPage/Info.svg";
import EyeSlashedIcon from "../../Assets/img/LoginSignupPage/EyeCross.svg";
import EyeIcon from "../../Assets/img/LoginSignupPage/EyeIcons.svg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import PageContainer from "../../Components/Container";

class Signup extends Component {
  designationOptions = [
    "Member of Panchayat",
    "School/ College Teacher",
    "Cultural Expert",
    "BDO or any Govt. Officer",
    "Local/ General Public",
  ];

  state = {
    isLoading: false,
    values: {
      name: "",
      email: "",
      password: "",
      phone: "",
      designation: "",
    },
    username: "",
    errMsgEmail: "",
    errMsgEmailPopuop: false,
    errMsgContact: "",
    errMsgPassword: "",
    errMsgPhone: "",
    errMsgDesignation: "",
    successMsg: "",
    passwordErrorMsg: "",
    showpassword: false,
    showsecondPassword: false,
    requestedUser: "simpleUser",
    showTooltip: false,
    showVerificationPopup: false,
  };

  handleTouchStart = () => {
    this.setState({ showTooltip: true });
    setTimeout(() => {
      this.setState({ showTooltip: false });
    }, 1500);
  };
  handleEmail = (e) => {
    var { values = {} } = this.state;
    var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    values.email = e.target.value;
    if (emailPattern.test(e.target.value)) {
      this.setState({ values, errMsgEmail: "", errMsgEmailPopuop: false });
    } else {
      this.setState({
        values,
        errMsgEmail: "Please Enter Valid Email Id",
        errMsgEmailPopuop: true,
      });
    }
  };

  handleNameChange = (e) => {
    var { values = {} } = this.state;

    this.setState({
      values: {
        ...this.state.values,
        name: e.target.value,
      },
    });
  };

  handlePhoneChange = (e) => {
    var { values = {} } = this.state;
    var phonePattern = /^[0-9]{10}$/;
    values.phone = e.target.value;
    if (phonePattern.test(e.target.value)) {
      this.setState({ values, errMsgPhone: "" });
    } else {
      this.setState({
        values,
        errMsgPhone: "Please enter a valid 10-digit phone number",
      });
    }
  };

  handleDesignationChange = (e) => {
    this.setState({
      values: {
        ...this.state.values,
        designation: e.target.value,
      },
    });
  };

  handlePassword = (e) => {
    var { values = {} } = this.state;

    var passwordPattern = /^(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,25}$/;
    values.password = e.target.value;

    if (passwordPattern.test(e.target.value)) {
      this.setState({ values, errMsgPassword: "" });
    } else {
      this.setState({
        values,
        errMsgPassword: "Minimum 8 characters and at least 1 special character",
      });
    }
  };

  handleConfPassword = (e) => {
    var { confPass = "" } = this.state;
    confPass = e.target.value;
    this.setState({ confPass });
  };

  validationForm(msj) {
    this.setState({
      passwordErrorMsg: msj,
      isLoading: false,
    });
  }

  checkPhoneExists = async (phone) => {
    const db = firebase.firestore();
    const usersRef = db.collection("users");
    const snapshot = await usersRef.where("values.phone", "==", phone).get();
    return !snapshot.empty;
  };

  handleSubmit = async (event, _userType) => {
    event.preventDefault();
    this.validationForm("");

    this.setState({ successMsg: false, error: false, isLoading: true });

    var email_add = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (
      this.state.values.name == "" ||
      this.state.values.email == "" ||
      this.state.values.password == "" ||
      this.state.values.phone == "" ||
      this.state.values.designation == ""
    ) {
      this.validationForm("Please fill all fields");
      setTimeout(() => {
        this.validationForm("");
      }, 1500);
    } else if (this.state.values.designation == "") {
      this.validationForm("Please select a designation");
      setTimeout(() => {
        this.validationForm("");
      }, 1500);
    } else {
      // Check if phone number already exists
      const phoneExists = await this.checkPhoneExists(this.state.values.phone);
      if (phoneExists) {
        this.validationForm("This phone number is already registered");
        setTimeout(() => {
          this.validationForm("");
        }, 1500);
        return;
      }

      // check if the value coming back is empty
      if (!email_add.test(this.state.values.email)) {
        this.validationForm("Please Enter Valid Email Id");
        setTimeout(() => {
          this.validationForm("");
        }, 1500);
      } else {
        if (
          this.state.errMsgPassword ||
          this.state.values.password.length < 8
        ) {
          this.validationForm(this.state.errMsgPassword);
        } else {
          if (this.state.values.password === this.state.confPass) {
            firebase
              .auth()
              .createUserWithEmailAndPassword(
                this.state.values.email,
                this.state.values.password
              )
              .then((res) => {
                let db = firebase.firestore().collection("users");
                db.doc(firebase.auth().currentUser.uid)
                  .set(
                    {
                      userType: _userType,
                      moderation: false,
                      is_profile_completed: true,
                      values: {
                        name: this.state.values.name,
                        email: firebase.auth().currentUser.email,
                        phone: this.state.values.phone,
                        designation: this.state.values.designation,
                      },
                      avatar: firebase.auth().currentUser.photoURL || "",
                    },
                    { merge: true }
                  )
                  .then(() => {
                    firebase
                      .auth()
                      .currentUser.sendEmailVerification()
                      .then(() => {
                        this.setState({
                          isLoading: false,
                          showVerificationPopup: true,
                          successMsg:
                            "Registration successful! Please check your email to verify your account.",
                        });
                      })
                      .catch((err) => {
                        console.error("Error sending verification email:", err);
                        this.setState({
                          isLoading: false,
                          successMsg:
                            "Registration successful, but there was an issue sending the verification email. Please contact support.",
                        });
                      });
                  })
                  .catch((err) => {
                    console.error("Error setting user data:", err);
                    this.setState({
                      isLoading: false,
                      successMsg: "Error occurred during registration. Please try again.",
                    });
                  });
              })
              .catch((err) => {
                console.log(err);
                this.validationForm("Email already exists");
                setTimeout(() => {
                  this.validationForm("");
                }, 1500);
                this.setState({
                  successMsg: "Email already exists or something went wrong",
                  error: true,
                  isLoading: false,
                });
              });
          } else {
            this.validationForm("Both Passwords should be the same!");
          }
        }
      }
    }
  };

  handleEmail = (e) => {
    var { values = {} } = this.state;
    var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    values.email = e.target.value;
    if (emailPattern.test(e.target.value)) {
      this.setState({ values, errMsgEmail: "", errMsgEmailPopuop: false });
    } else {
      this.setState({
        values,
        errMsgEmail: "Please Enter Valid Email Id",
        errMsgEmailPopuop: true,
      });
    }
  };

  handleRequestedUserChange = () => {
    if (this.state.requestedUser == "simpleUser")
      this.setState({
        requestedUser: "artist",
      });
    else
      this.setState({
        requestedUser: "simpleUser",
      });
  };

  //handle change in username
  handleChangeUsername = (e) => {
    this.setState({
      username: e.target.value,
    });
  };

  render() {
    const { t } = this.props;
    const tooltip = (
      <Tooltip id="tooltip" style={{ background: "transparent" }}>
        Who Is artist? <br />
        An artist is someone who uses their creativity to produce creative works
        like paintings, music, stories, and more, using a variety of skills and
        imagination.{" "}
      </Tooltip>
    );
    return (
      <PageContainer
        keywords="MGMD Sign Up, Create Account"
        title="Sign Up for MGMD | Join the Village Heritage Movement"
        description="Sign up today to be a part of MGMD’s initiative to preserve India’s cultural and village heritage. Create an account and explore exclusive insights and mapping features."
        canonical="https://mgmd.gov.in/signup"
      >
        <MyLayout
          sideheader={false}
          transparent={true}
          bg={OnboardingImage}
          bg_m={SingBG}
          footer={false}
        >
          <Modal
            show={this.state.showVerificationPopup}
            onHide={() => this.setState({ showVerificationPopup: false })}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Email Verification Required</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>A verification link has been sent to your email address. Please check your inbox and click on the link to verify your account.</p>
              <p>You won't be able to access your account until your email is verified.</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => this.setState({ showVerificationPopup: false })}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          <MyDesktop>
            <div className="signup-popup col-md-4 th-bg-white mx-auto th-br-20 py-1 d-flex justify-content-center position-relative">
              <Link to="/">
                <img
                  className="position-absolute img-fluid"
                  alt="..."
                  src={closeLogo}
                  style={{ right: "5%", top: "5%", height: "16px" }}
                ></img>
              </Link>

              <div style={{ width: "340px" }}>
                <div className="col-12 th-24 th-fw-500 pt-4 th-dark-grey text-center">
                  <span>Create Account</span>
                </div>
                <div className="col-12 th-12 th-fw-400 th-md-grey text-center">
                  <span>Enter your details to continue</span>
                </div>

                {this.state.passwordErrorMsg && (
                  <Alert
                    key={"danger"}
                    variant={"danger"}
                    className={"text-center"}
                  >
                    {this.state.passwordErrorMsg}
                  </Alert>
                )}

                <div className="pt-2">
                  <input
                    type="text"
                    placeholder="Full name"
                    className="w-100 p-1 form-control-plaintext shadow-none"
                    name="fullname"
                    onChange={this.handleNameChange}
                  />
                  <hr className="th-hr"></hr>
                </div>
                <div>
                  <input
                    type="text"
                    placeholder="E-mail Address"
                    className="w-100 p-1 form-control-plaintext shadow-none "
                    name="email"
                    onChange={(e) => this.handleEmail(e)}
                  />
                  <hr className="th-hr mb-1"></hr>
                  <div className="th-10 pb-1">{this.state.errMsgEmail}</div>
                </div>

                <div>
                  <input
                    type="tel"
                    placeholder="Phone Number"
                    className="w-100 p-1 form-control-plaintext shadow-none"
                    name="phone"
                    onChange={(e) => this.handlePhoneChange(e)}
                  />
                  <hr className="th-hr mb-1"></hr>
                  <div className="th-10 pb-1">{this.state.errMsgPhone}</div>
                </div>

                <div>
                  <select
                    className="w-100 p-1 form-control-plaintext shadow-none"
                    name="designation"
                    onChange={(e) => this.handleDesignationChange(e)}
                    value={this.state.values.designation}
                  >
                    <option value="" disabled>
                      Select Designation
                    </option>
                    {this.designationOptions.map((option, index) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                  <hr className="th-hr mb-1"></hr>
                  <div className="th-10 pb-1">
                    {this.state.errMsgDesignation}
                  </div>
                </div>

                <div className="password-page">
                  <input
                    type={this.state.showpassword ? "text" : "password"}
                    placeholder="Password"
                    className="w-100 p-1 form-control-plaintext shadow-none "
                    name="password"
                    onChange={(e) => this.handlePassword(e)}
                  />
                  <span
                    onClick={() => {
                      this.setState({ showpassword: !this.state.showpassword });
                    }}
                    className="eyeicon"
                  >
                    {this.state.showpassword === true ? (
                      <img src={EyeIcon} alt="..." height="20" width="20" />
                    ) : (
                      <img
                        src={EyeSlashedIcon}
                        alt="..."
                        height="20"
                        width="20"
                      />
                    )}
                  </span>
                </div>
                <hr className="th-hr mb-1"></hr>

                <div className="th-10 pb-1">{this.state.errMsgPassword}</div>
                <div className="pt-2 password-page">
                  <input
                    type={this.state.showsecondPassword ? "text" : "password"}
                    placeholder="Confirm Password"
                    className="w-100 p-1 form-control-plaintext shadow-none "
                    name="confirmpassword"
                    onChange={(e) => this.handleConfPassword(e)}
                  />
                  <span
                    onClick={() => {
                      this.setState({
                        showsecondPassword: !this.state.showsecondPassword,
                      });
                    }}
                    className="eyeicon"
                  >
                    {this.state.showsecondPassword == true ? (
                      <img src={EyeIcon} alt="..." height="20" width="20" />
                    ) : (
                      <img
                        src={EyeSlashedIcon}
                        alt="..."
                        height="20"
                        width="20"
                      />
                    )}
                  </span>
                  <hr className="th-hr"></hr>
                </div>
                <div className="pt-3">
                  <div className="row" style={{ marginLeft: "5px" }}>
                    <div className="row g-0">
                      <div className="col-2">
                        <div className="row justify-content-center">
                          <input
                            onClick={this.handleRequestedUserChange}
                            type="checkbox"
                            id="i-am-artist-checkbox"
                            className="checkbox-i-am-artist"
                            role="button"
                          />
                          <label className="custom-control-label"></label>
                        </div>

                        <div className="row ">
                          <span>
                            <p className="text-i-am-artist text-right">
                              <OverlayTrigger
                                placement="auto"
                                overlay={tooltip}
                              >
                                <span>
                                  <img
                                    src={InfoIcon}
                                    alt="..."
                                    className="icon-login"
                                  />{" "}
                                </span>
                              </OverlayTrigger>
                              I am artist
                            </p>
                          </span>
                        </div>
                      </div>
                      <div className="col-9">
                        {!this.state.isLoading ? (
                          <button
                            className="w-100 px-3 py-2 th-fw-400 th-btn-green "
                            onClick={(e) =>
                              this.handleSubmit(e, this.state.requestedUser)
                            }
                          >
                            <span>
                              Sign-up as{" "}
                              {this.state.requestedUser == "simpleUser"
                                ? "User"
                                : "Artist"}
                            </span>
                          </button>
                        ) : (
                          <button
                            className="w-100  px-3 py-2 th-btn-green d-flex align-items-center justify-content-center"
                            disabled
                          >
                            <span
                              className="spinner-grow spinner-grow-sm mr-2"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            Wait...
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-disclaimer-with-edit px-3 pt-2 text-center">
                  By clicking Sign Up, you agree to our Terms & Conditions and
                  Privacy Policy
                </div>
                <div className="col-12 th-12 pt-3 text-center th-md-grey pb-3">
                  Already have an account?
                  <Link to="/login">
                    <span className="th-primary">Sign In</span>
                  </Link>
                </div>
              </div>
            </div>
          </MyDesktop>
          <MyMobile>
            <div className="container">
              <div className="row px-1 mx-1 th-bg-white th-br-20 py-2 d-flex justify-content-center position-relative mobile-theme">
                <Link to="/explore">
                  <img
                    className="position-absolute close_btn_signup"
                    src={closeLogo}
                    alt="..."
                  />
                </Link>
                <div className="col-12 th-24 th-fw-500 pt-4 th-dark-grey text-center">
                  <span>Create Account</span>
                </div>
                <div className="col-12 th-16 th-fw-400 th-md-grey text-center">
                  <span>Enter your details to continue</span>
                </div>

                {this.state.passwordErrorMsg && (
                  <Alert
                    key={"danger"}
                    variant={"danger"}
                    className={"text-center"}
                  >
                    {this.state.passwordErrorMsg}
                  </Alert>
                )}

                <div className="pt-3 w-100">
                  <input
                    type="text"
                    placeholder="Full name"
                    className="w-100 p-1 form-control-plaintext shadow-none"
                    name="fullname"
                    onChange={this.handleNameChange}
                  />
                  <hr className="th-hr"></hr>
                </div>
                <div className="pt-2 w-100">
                  <input
                    type="text"
                    placeholder="E-mail Address"
                    className="w-100 p-1 form-control-plaintext shadow-none"
                    name="email"
                    onChange={(e) => this.handleEmail(e)}
                  />
                  <hr className="th-hr"></hr>
                </div>
                <div className="pt-2 w-100">
                  <input
                    type="tel"
                    placeholder="Phone Number"
                    className="w-100 p-1 form-control-plaintext shadow-none"
                    name="phone"
                    onChange={(e) => this.handlePhoneChange(e)}
                  />
                  <hr className="th-hr"></hr>
                </div>
                <div className="pt-2 w-100">
                  <select
                    className="w-100 p-1 form-control-plaintext shadow-none"
                    name="designation"
                    onChange={(e) => this.handleDesignationChange(e)}
                    value={this.state.values.designation}
                  >
                    <option value="" disabled>
                      Select Designation
                    </option>
                    {this.designationOptions.map((option, index) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                  <hr className="th-hr"></hr>
                </div>
                <div className="pt-2 w-100 password-page">
                  <input
                    type={this.state.showpassword ? "text" : "password"}
                    placeholder="Password"
                    className="w-100 p-1 form-control-plaintext shadow-none"
                    name="password"
                    onChange={(e) => this.handlePassword(e)}
                  />
                  <span
                    onClick={() => {
                      this.setState({ showpassword: !this.state.showpassword });
                    }}
                    className="eyeicon mobile"
                  >
                    {this.state.showpassword == true ? (
                      <img src={EyeIcon} alt="..." height="20" width="20" />
                    ) : (
                      <img
                        src={EyeSlashedIcon}
                        alt="..."
                        height="20"
                        width="20"
                      />
                    )}
                  </span>
                  <hr className="th-hr"></hr>
                </div>
                <div className="pt-2 w-100 password-page ">
                  <input
                    type={this.state.showsecondPassword ? "text" : "password"}
                    placeholder="Confirm Password"
                    className="w-100 p-1 form-control-plaintext shadow-none"
                    name="confirmpassword"
                    onChange={(e) => this.handleConfPassword(e)}
                  />
                  <span
                    onClick={() => {
                      this.setState({
                        showsecondPassword: !this.state.showsecondPassword,
                      });
                    }}
                    className="eyeicon mobile"
                  >
                    {this.state.showsecondPassword == true ? (
                      <img src={EyeIcon} alt="..." height="20" width="20" />
                    ) : (
                      <img
                        src={EyeSlashedIcon}
                        alt="..."
                        height="20"
                        width="20"
                      />
                    )}
                  </span>
                  <hr className="th-hr"></hr>
                </div>
                <div className="pt-4 w-100">
                  <div className="row">
                    <div className="row g-0 justify-content-center signup_alignment">
                      <div className="col-3">
                        <div className="row justify-content-center">
                          <input
                            onClick={this.handleRequestedUserChange}
                            type="checkbox"
                            id="i-am-artist-checkbox"
                            className="checkbox-i-am-artist-mobile"
                            role="button"
                          />
                          <label className="custom-control-label"></label>
                        </div>

                        <div className="row ">
                          <span>
                            <div className="text-i-am-artist-mobile">
                              <OverlayTrigger
                                placement="auto"
                                overlay={tooltip}
                                trigger={["click", "touch"]}
                                show={this.state.showTooltip}
                              >
                                <span onTouchStart={this.handleTouchStart}>
                                  <img
                                    src={InfoIcon}
                                    alt="..."
                                    className="icon-login"
                                  />{" "}
                                </span>
                              </OverlayTrigger>
                              I am artist
                            </div>
                          </span>
                        </div>
                      </div>
                      <div className="col-8">
                        {!this.state.isLoading ? (
                          <button
                            className="w-100 px-3 py-2 th-fw-400 th-btn-green "
                            onClick={(e) =>
                              this.handleSubmit(e, this.state.requestedUser)
                            }
                          >
                            <span>
                              Sign-up as{" "}
                              {this.state.requestedUser == "simpleUser"
                                ? "User"
                                : "Artist"}
                            </span>
                          </button>
                        ) : (
                          <button
                            className="w-100 px-3 py-2 th-btn-green d-flex align-items-center justify-content-center"
                            disabled
                          >
                            <span
                              className="spinner-grow spinner-grow-sm mr-2"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            Wait...
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="text-disclaimer-with-edit px-3 pt-2 text-center">
                    By clicking Sign Up, you agree to our Terms & Conditions and
                    Privacy Policy
                  </div>
                </div>
                <div className="col-12 th-16 pt-3 pb-3 text-center th-md-grey">
                  Already have an account?
                  <Link to="/login">
                    <span className="th-primary">Sign In</span>
                  </Link>
                </div>
              </div>
            </div>
          </MyMobile>
        </MyLayout>
      </PageContainer>
    );
  }
}

export default withRouter(Signup);
